import { AuthProvider } from 'react-admin';
import { authenticate } from '../RestAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
localStorage.setItem('username', 'Jane Doe');

export const authProvider = {
    login: ({username, password}) => {
        return authenticate({userIdentifier: username, userPassword: password}).then((response) => {
            if (401 === response.statusCode) {
                localStorage.removeItem('jwt');
                window.location = '/#/login';
            } else if ( response.statusCode >= 300 || response.statusCode < 200 || response.statusCode === 201) {
                if(response.data && response.data.error) {
                    console.log(response);
                    toast.error(response.data.error.message ? response.data.error.message : response.data.error.name);
                    throw new Error(response.data.error.name);
                }
            }
            localStorage.setItem('jwt', response.data.jwt || '')
        }).catch((err) => {
            console.log(err)
            toast.error(err)
            throw new Error(err.statusText);
        })
    },
    logout: () => {
        localStorage.removeItem('jwt');
        return Promise.resolve();
    },
    checkError: (error) => toast.error(error),
    checkAuth: () => localStorage.getItem('jwt') ? Promise.resolve() : Promise.reject({ redirectTo: '/#/login' }),
    getPermissions: () => {console.log("Unknown method"); return localStorage.getItem('jwt') ? Promise.resolve('Unknown method') : Promise.reject('Unknown method')},
    getIdentity: () => {
        return new Promise((resolve, reject) => {
            const jwt = localStorage.getItem('jwt')
            if(!jwt) reject()
            resolve(jwt)
        })
    }
};