import React, { Component, Fragment } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import { SimpleForm, Button, DeleteButton, TextInput, LongTextInput, required } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  }
}));
const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
});

function BlackTooltip(props) {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}
class CustomDeleteAction extends Component {
  state = {
    showDialog: false,
    title:null,
    button:null,
    btnClass:null,
    label:null,
    message:null,
    disablebutton:false,
    resource:null,
    isDeleted:false
  };
  componentDidMount() {
    // Changing the state after 3 sec
    let rec = this.props.record;
    // console.log(this.props)
    // console.log(rec)
    let resource = this.props.resource;
    if(rec.deletedOn) {
      this.setState({ disablebutton: false});
      if(resource === "campaigns") {
        this.setState({ title: 'Are you sure you want to activate this campaign?' });
        this.setState({ message: 'This may impact gigs attached to this campaign.' });
      } else if(resource === "gigs") {
        this.setState({ title: 'Are you sure you want to activate this gig?' });
        this.setState({ message: 'This may impact campaign attached to this gig.' });
      } else if(resource === "invites") {
        this.setState({ title: 'Are you sure you want to activate this invite?' });
        this.setState({ message: 'You are not allowed to activate this invite.' });
        this.setState({ disablebutton: true});
      } else {
        this.setState({ title: 'Are you sure you want to activate this entity?' });
        this.setState({ message: 'This may impact other entities attached to this entity.' });
      }
        this.setState({ btnClass: 'text-success' });
        this.setState({ label: 'ACTIVATE' });
        this.setState({ isDeleted: true});
    } else {
        this.setState({ isDeleted: false});
        if(resource === "campaigns") {
          this.setState({ title: 'Are you sure you want to delete this campaign?' });
          this.setState({ message: 'This may impact gigs attached to this campaign.' });
        } else if(resource === "gigs") {
          this.setState({ title: 'Are you sure you want to delete this gig?' });
          this.setState({ message: 'This may impact campaign attached to this gig.' });
        } else if(resource === "invites") {
          this.setState({ title: 'Are you sure you want to delete this invite?' });
          this.setState({ message: 'You may choose the decline the request instead of delete.' });
        } else {
          this.setState({ title: 'Are you sure you want to delete this entity?' });
          this.setState({ message: 'This may impact other entities attached to this entity.' });
        }
        
        this.setState({ btnClass: 'ra-delete-button' });
        this.setState({ label: 'ra.action.delete' });
       
    }
       
    
 }
  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };
  
  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const { dispatchCrudDelete, startUndoable, resource, record, basePath, redirect, undoable } = this.props;
    if (undoable) {
      startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
    } else {
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  };

  render() {
    const { showDialog } = this.state;
    const {record,  classes = {}, className } = this.props;
    let button;
    let btnClass;
    if (record.deletedOn && !this.state.disablebutton) {
      button = <CheckIcon />;
      btnClass = 'text-success';
    } else {
      button = <ActionDelete />;
      btnClass = 'ra-delete-button';
    }
    return (
      <Fragment>
        <BlackTooltip title="Delete Invite" aria-label="Delete Invite" placement="top" arrow>
        <button onClick={this.handleClick} label={this.state.label} className={classnames('btn  iconbox iconbox-xs', btnClass, classes.deleteButton, className)} key="button" disabled={this.state.disablebutton}>
            {button}
        </button>
        </BlackTooltip>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
          <DialogTitle>{this.state.title}</DialogTitle>
          <DialogContent>
            <div>
            {this.state.message}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelete} label={this.state.label} className={classnames(this.state.btnClass, classes.deleteButton, className)} key="button" disabled={this.state.disablebutton}>
              {button}
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

CustomDeleteAction.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool
};

CustomDeleteAction.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudDelete: crudDelete }
  ),
  translate,
  withStyles(styles)
)(CustomDeleteAction);