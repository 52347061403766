import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    
    Typography,
    makeStyles,
    Slide
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import WarningIcon from '@material-ui/icons/Warning';
import PowerOffIcon from '@material-ui/icons/ExitToApp';
import ThumbUpIcon from '@material-ui/icons/DoubleArrow';
const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 0
    },
    button: {
        borderRadius: 0,
        textTransform: "none",
        padding: 5
    },
    logout: {
        color: "#fff",
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700]
        }
    },
    countdown: {
        color: red[700]
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialog }}
            TransitionComponent={Transition}>
            <DialogTitle >
            <h1 className="text-danger"><WarningIcon className="svg_icons"/> <span className="ml-3 mt-3">Session Timeout</span></h1>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" className="font-weight-normal">
                    The current session is about to expire in{" "}
                    <span className={classes.countdown}>{countdown}</span> seconds.
                </Typography>
                <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={onLogout}
                    className="btn btn-danger m-3 " ><PowerOffIcon /> Logout</button>
                <button
                    onClick={onContinue}
                    className="btn btn-success m-3 " ><ThumbUpIcon /> Continue Session</button>
            </DialogActions>
        </Dialog>
    );
}
export default SessionTimeoutDialog;