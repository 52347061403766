import React, {useState, useEffect, Fragment} from 'react';
import { useAuthenticated } from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { getAmbassadors, inviteCampaign } from '../RestAPI'
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button,  List, Filter, SearchInput, SimpleShowLayout, SimpleForm } from 'react-admin';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import {  toast } from 'react-toastify';

const styles = (theme) => ({
  dialogCustomizedWidth: {
    'max-width': '70%'
  },
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
});

const TagFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" resettable alwaysOn />
  </Filter>
)

  const CustomInviteAction = (props) => {
    useAuthenticated()
    const [showDialog, setShowDialog] = useState(false);
    const [campaign, setCampaign] = useState({});
    const [ambassadors, setAmbassadors] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(async () => {
      let param = {
        filter: {},
        pagination: {
          page: 1,
          perPage: 20
        },
        sort: {
          field: 'id',
          order: 'ASC'
        }
      }
      console.log(props)
      setCampaign(props.record);
      let data = await getAmbassadors(param);
      console.log(data)
      if (data && data.data) {
        setAmbassadors(data.data );
      }

    },[]);

    const selectUser = (id) => {
      setSelectedUser(id)
    }
    useEffect( () => {
      console.log(selectedUser);
    },[selectedUser]);
    const Invite = ({ ids, data, basePath }) => (
      <div className="container-fluid">
      <div className="row" style={{ margin: '1em', paddingRight:'20px' }} >
        {ids.map(id =>
          <div className="col col-md-3 col-lg-2 col-sm-4 mb-5 " key={id}>
            <div className={`card w-100 h-100 shadow invite-card-cursor ${id === selectedUser ? 'invite-card':''}`} onClick={()=>selectUser(id)}>
            <label className="ec-radio radio-lg mr-3" style={{position:'absolute'}}>
              <input type="radio" name="selectedUser"  value={id} checked={id===selectedUser} readOnly />
              <span className="ec-radio__control"></span>
            </label>
              <div className="text-center " >
                <div className=" py-2 px-2 "><div style={{ alignItem: 'center' }}><img src={data[id].profileImageUrl ? data[id].profileImageUrl : '/assets/img/logo/user-512.png'} className="avatar__img small_profile_image rounded-circle border border-width-4" /></div>
                  <div className="font-weight-lighter line-break">{data[id].lastName}, {data[id].firstName}  </div>
                  <div className="font-weight-lighter line-break">{data[id].email}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    );
    const handleClick = () => {
      setShowDialog( true );
    
    };
    
    const handleCloseClick = () => {
      setShowDialog( false );
    
    };
    
    const handleInvite = async (event) => {
      event.preventDefault();
      // setShowDialog(false );
      const { dispatchCrudDelete, startUndoable, resource, record, basePath, redirect, undoable } = props;
      console.log('selectedUser ', selectedUser);
      console.log('props ', props);
      console.log('campaign ', campaign);
      campaign.selectedUser = selectedUser;
      
      let data = await inviteCampaign(campaign);
      if(data && data.data && data.data.error) {
        toast.error(data.data.error)
      } else {
        toast.success('Invite sent successfully')
      }

    };
   
    const { record, classes = {}, className } = props;
    
    let fakeProps = {
      basePath: "/ambassadors",
      hasCreate: false,
      hasEdit: false,
      hasList: true,
      hasShow: false,
      history: {},
      location: { pathname: "/", search: "", hash: "", state: undefined },
      match: { path: "/", url: "/", isExact: true, params: {} },
      options: {},
      permissions: null,
      resource: "ambassadors"
    }
    return (
      <Fragment>
        <button onClick={handleClick} type="button" className="btn btn-outline-info btn-xs"  >
          <>  <ContactMailIcon /> INVITE </>
        </button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Invite Ambassador" classes={{ paperFullWidth: classes.dialogCustomizedWidth }}>
          <DialogTitle>Invite Ambassador</DialogTitle>
          <DialogContent>
            <SimpleShowLayout>
              <SimpleForm toolbar={false}>
                <div className="invites">
                  <List {...fakeProps} filters={<TagFilter />} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false} exporter={false} className="p-2">
                    <Invite />
                  </List>
                </div>
              </SimpleForm >
            </SimpleShowLayout>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInvite} label="INVITE" className={classnames(className)} key="button">
              <ContactMailIcon />
            </Button>
            <Button label="ra.action.close" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  
}

CustomInviteAction.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
};

CustomInviteAction.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudDelete: crudDelete }
  ),
  translate,
  withStyles(styles)
)(CustomInviteAction);