import React, {useState, useEffect, Fragment} from 'react';
import { useAuthenticated } from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { locationUpdate } from '../RestAPI'
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, SimpleShowLayout, SimpleForm, useRefresh } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import {  toast } from 'react-toastify';
import EditIcon from '@material-ui/icons/Edit';
import LocationIcon from '@material-ui/icons/LocationOn';
import  MTextField  from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  }
}));

function BlackTooltip(props) {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}
const styles = (theme) => ({
  dialogCustomizedWidth: {
    'max-width': '40%'
  },
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
});

  const UpdateLocationAction = (props) => {
    useAuthenticated()
    const [showDialog, setShowDialog] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const myrefresh = useRefresh();

    useEffect(async () => {
      console.log(props)
      const {record } = props;
      if (record && record.addresses && record.addresses.length > 0) {
        setLatitude( parseFloat( record.addresses[0].latitude));
        setLongitude ( parseFloat(record.addresses[0].longitude));

    }
    },[]);

    const handleClick = () => {
      setShowDialog( true );
    
    };
    
    const handleCloseClick = () => {
      setShowDialog( false );
    
    };
    
    const handleChange = (event) => {
      if (event.target.name === "latitude") {
        setLatitude( event.target.value);
      } else if (event.target.name === "longitude") {
        setLongitude ( event.target.value);
      }
    }

    const handleSave = async (event) => {
      event.preventDefault();
      // setShowDialog(false );
      const { dispatchCrudDelete, startUndoable, resource, record, basePath, redirect, undoable } = props;
      console.log('record ', record);
      console.log('latitude ', latitude);
      console.log('longitude ', longitude);
      record.lat = latitude;
      record.lng = longitude;
      let data = await locationUpdate(record);
      if(data && data.data && data.data.error) {
        toast.error(data.data.error)
      } else {
        setShowDialog( false );
        toast.success('Location updated successfully')
        myrefresh()
      }
    };
   
    const { record, classes = {}, className } = props;
    
    return (
      <Fragment>
        <BlackTooltip title="Edit Location" aria-label="Edit Location" placement="top" arrow>
        <button onClick={handleClick} type="button" className="btn iconbox iconbox-xs btn-light text-info ml-3"  >
        <EditIcon />
        </button>
        </BlackTooltip>
        <Dialog  open={showDialog} onClose={handleCloseClick} aria-label="Save Location" classes={{ paperFullWidth: classes.dialogCustomizedWidth }}>
          <DialogTitle>Save Location</DialogTitle>
          <DialogContent>
            <SimpleShowLayout>
              <SimpleForm toolbar={false}>
              <div className="row">
                
                <div className="col col-md-4 col-lg-4 col-sm-4 align-middle mr-0" ><span className="font-weight-bold">Location: </span><span className="danger"><LocationIcon /> </span></div>
                <div className="col col-md-4 col-lg-4 col-sm-4 " ><MTextField id="latitude" name="latitude" className="form-control" type="number" label="Latitude" variant="standard"  value={latitude} onChange={handleChange} /></div>
                <div className="col col-md-4 col-lg-4 col-sm-4 " ><MTextField id="longitude" name="longitude" className="form-control" type="number" label="Longitude" variant="standard"  value={longitude} onChange={handleChange} /></div>
                    
            </div>
              </SimpleForm >
            </SimpleShowLayout>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} label="SAVE" className={classnames(className)} key="button">
              <SaveIcon />
            </Button>
            <Button label="ra.action.close" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  
}

UpdateLocationAction.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
};

UpdateLocationAction.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudDelete: crudDelete }
  ),
  translate,
  withStyles(styles)
)(UpdateLocationAction);