import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {
    useAuthenticated,
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    NullableBooleanInput,
} from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {  toast } from 'react-toastify';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { updatePaymentStatus } from '../RestAPI'

const CustomPaymentStatusButton = ({ selectedIds }) => {
    useAuthenticated()
    const [open, setOpen] = useState(false);
    const [paymentOption, setPaymentOption] = useState("Paid");
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'earnings',
        selectedIds,
        { option: paymentOption},
        {
            onSuccess: () => {
                refresh();
                toast.success("Payment status updated successfully");
                // notify('Payment status updated', { undoable: true });
                unselectAll('earnings');
            },
            onFailure: error => toast.error(error),
            mutationMode: 'undoable'
        }
    );

    const handleApprove = async (event) => {
        event.preventDefault();
        console.log("selectedIds = ", selectedIds)
        let payload = {
            ids: selectedIds,
            data: {
                option: paymentOption
            }
        }
        setOpen(false);
         console.log(payload)
       let data = await updatePaymentStatus(payload);
       console.log(data)
       if(!data || data.error) {
        toast.error(data.error);
       } else {
        refresh();
        unselectAll('earnings');
        toast.success("Payment status updated successfully");
       }
      
    
      };

    const handleClick = () => setOpen(true)
    const handleDialogClose = () => setOpen(false)
    const selectOption = (option) => setPaymentOption(option)


    return (
        <Fragment>
            
            <Button label="Update Payment" onClick={handleClick} ><SaveIcon /></Button>
            <Dialog  open={open} loading={loading} onClose={handleDialogClose} aria-label="Update Payment Status">
          <DialogTitle><h3><InfoIcon className="svg_icons_small" />  Update Payment Status</h3></DialogTitle>
          <DialogContent>
          <div className="card">
            <div className="card-body" style={{padding:'0rem'}}>
            <div className="">
              <span >Are you sure you want to update the payment status?</span>
              </div>
              <div className=" mt-4">
              <div className="form-group">
                <span className="mt-1">Choose payment status: </span>
                <label className="ec-radio mr-3">
                <input type="radio" name="paymentStatus" checked onClick={()=>selectOption('Paid')}/>
                <span className="ec-radio__control"></span>
                <span className="ec-radio__label">Paid</span>
                </label>
                <label className="ec-radio mr-3">
                <input type="radio" name="paymentStatus" onClick={()=>selectOption('Unpaid')}/>
                <span className="ec-radio__control"></span>
                <span className="ec-radio__label">UnPaid</span>
                </label>
                </div>
            </div>
            </div>
          </div>
  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApprove} label="Confirm" className="" key="button">
              <CheckIcon />
            </Button>
            <Button label="ra.action.cancel" onClick={handleDialogClose}>
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
        </Fragment>
    );
}

export default CustomPaymentStatusButton;