import * as React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import Footer from "../Footer"
import { ToastContainer } from 'react-toastify';
import SessionTimeout from '../SessionTimeout';
import { useAuthState, Loading, useLogout } from 'react-admin';
import NotFound from "../NotFound"
const MyLayout = (props) => {
    const { loading, authenticated } = useAuthState();
    const logout = useLogout();
    const handleClick = () => {localStorage.removeItem('jwt');logout();}

    if (loading) {
        return <Loading />;
    }
    if (authenticated) {
        return (
            <> <Layout {...props} appBar={MyAppBar} style={{ overflow: "auto" }} />  <ToastContainer autoClose={8000} position="bottom-center" pauseOnFocusLoss={false} pauseOnHover theme="dark" /> <Footer />  <SessionTimeout isAuthenticated={authenticated} logOut={handleClick} />
                {localStorage.getItem('isLoading') && (
                    <div id="preloader">
                        <div id="loader"></div>
                    </div>
                )}
            </>)
    } else {
        return (
        <React.Fragment>
        <NotFound />
        </React.Fragment>
        )
    }
};

export default MyLayout;