import React, { useState, useEffect, Fragment } from 'react';
import { useAuthenticated } from 'react-admin';
import { toast } from 'react-toastify';
import { approveInvite } from '../RestAPI'
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Button, useRefresh } from 'react-admin';

function CustomApproveAction(props) {
    useAuthenticated()
    const [showDialog, setShowDialog] = useState(false);
    const [title, setTitle] = useState(null);
    const [button, setButton] = useState(null);
    const [btnClass, setBtnClass] = useState(null);
    const [label, setLabel] = useState(null);
    const [message, setMessage] = useState(null);
    const [disablebutton, setDisablebutton] = useState(false);
    const [resource, setResource] = useState(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const myrefresh = useRefresh();


    useEffect(async () => {
        // Changing the state after 3 sec

        let rec = props.record;

        let resource = props.resource;
        if (!rec.deletedOn) {
            setDisablebutton(false);
            if (resource === "campaigns") {
                setTitle('Are you sure you want to activate this campaign?');
                setMessage('This may impact gigs attached to this campaign.');
            } else if (resource === "gigs") {
                setTitle('Are you sure you want to activate this gig?');
                setMessage('This may impact campaign attached to this gig.');
            } else if (resource === "invites") {
                setTitle('Are you sure you want to activate this invite?');
                setMessage('An email will be send to the user with the status. If you want to reject this request, please CANCEL this dialog and use the DELETE action.');
            } else {
                setTitle('Are you sure you want to activate this entity?');
                setMessage('This may impact other entities attached to this entity.');
            }
            setBtnClass('text-success');
            setLabel('ACTIVATE');
            setIsDeleted(true);
            setButton(<CheckIcon />);
        } else {
            if (rec.deletedOn) {
                setIsDeleted(true);
                setDisablebutton(true);
            } else {
                setIsDeleted(false);
                setDisablebutton(false);
            }
            if (resource === "campaigns") {
                setTitle('Are you sure you want to delete this campaign?');
                setMessage('This may impact gigs attached to this campaign.');
            } else if (resource === "gigs") {
                setTitle('Are you sure you want to delete this gig?');
                setMessage('This may impact campaign attached to this gig.');
            } else if (resource === "invites") {
                setTitle('Are you sure you want to activate this invite?');
                setMessage('An email will be send to the user with the status.');
            } else {
                setTitle('Are you sure you want to delete this entity?');
                setMessage('This may impact other entities attached to this entity.');
            }
            setButton(<CloseIcon />);
            setBtnClass('ra-delete-button');
            setLabel('ra.action.delete');

        }
        if (resource === "invites" && !rec.deletedOn && rec.status === "Approved") {
            setDisablebutton(true);
        }

    }, []);


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleApprove = async (event) => {
        event.preventDefault();
        setShowDialog(false);
        console.log(props)
        let payload = props.record;
        console.log(payload)
        let data = await approveInvite(payload);
        console.log(data)
        if (!data || data.error) {
            toast.error(data.error);
            myrefresh();
        } else {
            setDisablebutton(true);
            toast.success("User approved successfully");
            myrefresh();
        }
        
    };

    return (
        <Fragment>
            <button onClick={handleClick} label={label} className={classnames('btn  iconbox iconbox-xs btn-light text-info', props.className)} key="button" disabled={disablebutton}>
                <HowToRegIcon />
            </button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Are you sure?">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div>
                        {message}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApprove} label={label} className={classnames(btnClass, props.className)} key="button" disabled={disablebutton}>
                        {button}
                    </Button>
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default CustomApproveAction;