
import { useAuthenticated } from 'react-admin';
import { Show, SimpleShowLayout, SimpleForm, TextInput, useRecordContext, ListButton, ImageField, TopToolbar, TextField, TabbedShowLayout, Tab, ChipField, ArrayField, SingleFieldList } from 'react-admin';
import { Container, Row, Col, Card } from 'react-bootstrap';
import FlagIcon from '@material-ui/icons/Flag';
import CustomInviteAction from "../../utils/CustomInviteAction"
import ReactPlayer from 'react-player'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import Moment from 'moment';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const useStyles = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black
    },
}));
function BlackTooltip(props) {
    const classes = useStyles();

    return <Tooltip arrow classes={classes} {...props} />;
}
const CampaignStatus = ({ source }) => {
    const record = useRecordContext();
    try {
        if (record && record.deletedOn) {
            return (<span className="badge badge-pill badge-danger">Deleted</span>);
        } else {
            return (<span className="badge badge-pill badge-info">{record[source]}</span>);
        }
    } catch (e) {
        console.error(e)
    }
};

const Category = ({ source }) => {
    const record = useRecordContext();
    try {
        return (<span className="badge badge-soft-info ml-2">{record[source]}</span>);
    } catch (e) {
        console.error(e)
    }
};

const ProfileImage = ({ source }) => {
    const record = useRecordContext();
    if (record) {
        return (<ImageField source={source} className="avatar__img rounded-circle border border-width-4" />);
    } else {
        return (<img src="/assets/img/logo/user-512.png" alt="Profile" className="avatar__img rounded-circle border border-width-4" />);
    }
};
const Gig = ({ source }) => {
    const record = useRecordContext();
    if (record) {
        return (
            <div className="col-md-6 col-lg-4 mt-2 text-center" style={{ minWidth: '15rem', minHeight: '10rem' }}>
                <div className="card py-2 px-2 shadow">
                    <h4 className="mt-3 mb-2">{record.gigName}</h4>
                    <div><strong>Description:</strong>{record.gigDescription}, {record.gigType}</div>
                    <div><strong>Delivery:</strong>{record.productDelivery}</div>
                    <div><strong>Additional Details:</strong>{record.additionalDetails}</div>
                    <div><strong>Requirement:</strong>{record.gigRequirement}</div>
                    <div ><span className="badge badge-pill badge-light font-weight-light"><u>Video Settings</u></span></div>
                    <div className="font-weight-lighter">{record.contentSettings}, {record.videoDuration}</div>
                    <div className="font-weight-lighter">{record.videoScenario}</div>
                </div>
            </div>
        );
    } else {
        return (<ChipField source={source} clickable={false} />);
    }
};

const Invite = ({ source }) => {
    const record = useRecordContext();
    console.log(source)
    console.log(record)
    if (record) {
        return (
            <div className="col-md-6 col-lg-4 mt-2 text-center" style={{ minWidth: '15rem', minHeight: '10rem' }}>
                <div className="card py-2 px-2 shadow"><div style={{ alignItem: 'center' }}><img src={record?.user?.profileImageUrl} className="avatar__img small_profile_image rounded-circle border border-width-4" /></div>
                    <h4 className="mt-3 mb-2">{record?.user?.lastName}, {record?.user?.firstName}</h4>
                    <div>{record?.user?.email}</div>
                    <div>{record?.user?.gender}</div>
                    <div ><span className="badge badge-pill badge-info font-weight-light">{record?.inviteStatus}</span></div>

                    <div className="font-weight-normal">
                        <Facebook source="facebookAccount" /> <Instagram source="instagramAccount" /> <YouTube source="youtubeUrl" />
                    </div>

                </div>
            </div>
        );
    } else {
        return (<ChipField source={source} clickable={false} />);
    }
};

const VideoPlayer = ({ source }) => {
    const record = useRecordContext();
    if (record && record.s3Key) {
        return (<ReactPlayer url={record.s3Key} controls={true} />);
    } else {
        return (<span className="font-weight-bold">No Video Resume</span>)
    }

};

const Submission = ({ source }) => {
    const record = useRecordContext();
    console.log(source)
    console.log(record)
    if (record) {
        return (
            <div className="col-md-6 col-lg-4 mt-2 text-center" style={{ minWidth: '15rem', minHeight: '10rem' }}>
                <div className="card py-2 px-2 shadow"><div style={{ alignItem: 'center' }} className="campaign-video"><VideoPlayer /> </div>
                    <h4 className="mt-3 mb-2">{record.gig?.gigName}</h4>
                    <div>{record.gig?.gigDescription}</div>
                    <div>{record.notes}</div>
                    <div ><span className="badge badge-pill badge-info font-weight-light">{record.submissionStatus}</span></div>


                </div>
            </div>
        );
    } else {
        return (<ChipField source={source} clickable={false} />);
    }
};
const Facebook = ({ source }) => {
    const record = useRecordContext();
    if (record && record.user?.facebookAccount) {
        return (<button className="btn btn-light iconbox iconbox-xs mr-1" onClick={() => window.open('https://www.facebook.com/' + record.user.facebookAccount)} >
            <FacebookIcon className="text-facebook" />
        </button>);
    } else {
        return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <FacebookIcon className="text-facebook" />
        </button>);
    }
};

const Instagram = ({ source }) => {
    const record = useRecordContext();
    if (record && record.user?.instagramAccount) {
        return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.instagram.com/' + record.user.instagramAccount)}>
            <InstagramIcon className="text-instagram" />
        </button>);
    } else {
        return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <InstagramIcon className="text-instagram" />
        </button>);
    }
};

const YouTube = ({ source }) => {
    const record = useRecordContext();
    if (record && record.user?.youtubeUrl) {
        return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.youtube.com/c/' + record.user.youtubeUrl)}>
            <YouTubeIcon className="text-youtube" />
        </button>);
    } else {
        return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <YouTubeIcon className="text-youtube" />
        </button>);
    }
};

const Conversation = ({ source }) => {
    const record = useRecordContext();
    if (record && record.user?.youtubeUrl) {
        return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.youtube.com/c/' + record.user.youtubeUrl)}>
            <YouTubeIcon className="text-youtube" />
        </button>);
    } else {
        return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <YouTubeIcon className="text-youtube" />
        </button>);
    }
};

const PostShowActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton label="BACK" basePath={basePath} record={data} className="btn btn-link btn-xs mr-2" icon={<ChevronLeft />} />
            <CustomInviteAction record={data} />
        </TopToolbar>
    )
};
const Aside = () => {
    const record = useRecordContext();
    console.log(record);
    var messages = record?.messages || [];
    console.log(messages);
    const senderId = record?.messages?.length > 0 ? record.messages[0].sender_id : 0;
    return (
        <section className="height-vh flex-top aside">
            <div className="container h-100">
                <div className="row mt-1 flex-center h-100 align-items-center">
                    <div className="col-lg-12 h-100">
                        <Card className="h-100 rounded shadow-md">
                            <Card.Body className="conversation-card">
                                <div className=' row border-bottom '>
                                    <div className='col-lg-5 ml-2'>
                                        <h4>Conversation</h4>
                                    </div>
                                    <div className='col-lg-5 mr-2 text-right'>
                                        <ul className="list-inline" >
                                            {(messages?.length > 0) && (
                                                <>
                                                    <li key={messages[0]?.sender_id} className="list-inline tools-blurb shadow ml-n2" >
                                                        <BlackTooltip title={`${messages[0]?.sender_lastName}, ${messages[0]?.sender_firstName}`} aria-label={`${messages[0]?.sender_lastName}, ${messages[0]?.sender_firstName}`} placement="top" arrow>
                                                            <img src={messages[0]?.sender_profileImageUrl} />
                                                        </BlackTooltip>
                                                    </li>
                                                    <li key={messages[0]?.sender_id} className="list-inline tools-blurb shadow ml-n2" >
                                                        <BlackTooltip title={`${messages[0]?.receiver_lastName}, ${record?.messages[0]?.receiver_firstName}`} aria-label={`${messages[0]?.receiver_lastName}, ${messages[0]?.receiver_firstName}`} placement="top" arrow>
                                                            <img src={messages[0]?.receiver_profileImageUrl} />
                                                        </BlackTooltip>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className=' col-lg-12 ' style={{ maxHeight: '700px', overflow: 'auto' }}>
                                    {(messages?.length > 0) && (
                                        <ul className="conversation-list-inline" >
                                            {messages.map((message, i) =>
                                                <li key={message?.sender_id} className="" >
                                                    {messages[i].sender_id === senderId && (
                                                        <>
                                                            <div className="d-flex align-items-start px-0">
                                                                <div className='row '>

                                                                    <div className="conversation-blurb ml-2 shadow  v-100 mt-3">
                                                                        <BlackTooltip title={`${message?.sender_lastName}, ${message?.sender_firstName}`} aria-label={`${message?.sender_lastName}, ${message?.sender_firstName}`} placement="top" arrow>
                                                                            <img src={messages[0].sender_profileImageUrl} />
                                                                        </BlackTooltip>
                                                                    </div>

                                                                    <div className=' ' style={{ paddingLeft: '0px!important', paddingRight: '0px!important', maxWidth: '90%' }}>
                                                                        <div className="alert alert-info conversation-alert ml-2 " role="alert">
                                                                            {message?.message_message}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="d-flex align-items-start px-0">
                                                                <div className=" row" >
                                                                    <p className="text-muted font-weight-light ml-4 align-top conversation-time">
                                                                        {Moment(message?.message_createdOn).format('MM/DD/YYYY hh:mm:ss a')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {messages[i].sender_id !== senderId && (
                                                        <>
                                                            <div className="d-flex flex-row-reverse align-items-start px-0">
                                                                <div className='row '>
                                                                    <div className=' ' style={{ paddingLeft: '0px!important', paddingRight: '0px!important', maxWidth: '90%' }}>
                                                                        <div className="alert alert-primary mr-2 conversation-alert" role="alert">
                                                                            {message?.message_message}
                                                                        </div>
                                                                    </div>
                                                                    <div className=' ' style={{ paddingLeft: '0px!important', paddingRight: '0px!important' }}>
                                                                        <div className="conversation-blurb mr-2 shadow v-100 mt-3">
                                                                            <BlackTooltip title={`${message?.sender_lastName}, ${message?.sender_firstName}`} aria-label={`${message?.sender_lastName}, ${message?.sender_firstName}`} placement="top" arrow>
                                                                                <img src={messages[0].receiver_profileImageUrl} />
                                                                            </BlackTooltip>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="d-flex flex-row-reverse align-items-start px-0">
                                                                <div className=" row" >
                                                                    <p className="text-muted font-weight-light mr-5 align-top conversation-time">
                                                                        {Moment(message?.message_createdOn).format('MM/DD/YYYY hh:mm:ss a')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </li>
                                            )}
                                        </ul>
                                    )}

                                    {(messages?.length === 0) && (
                                        <div className="alert alert-danger alert-dismissible mt-4 fade show" role="alert">
                                            <div className="media align-items-center">
                                                <i class="fas fa-exclamation-triangle font-size-20"></i>
                                                <div className="media-body pl-2">
                                                   <span className="font-weight-normal"> No conversation.</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}
const Campaign = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><FlagIcon /> Campaign</h3>
            <Row className="justify-content-center mt-5 campaign">

                <Col>

                    <Show aside={<Aside />} actions={<PostShowActions />} {...props} title="Campaign" className="shadow-md p-4">
                        <SimpleShowLayout>
                            <SimpleForm toolbar={<></>}>
                                <div className="row ">
                                    <div className="col-lg-8" >
                                        <Card style={{ minWidth: '30rem', minHeight: '20rem' }} >
                                            <Card.Body style={{ minWidth: '65rem' }}>
                                                <Card.Title>Campaign Info</Card.Title>
                                                <div className="row" >
                                                    <div className="col-lg-6" >
                                                        <div className="media">
                                                            <div className="avatar avatar-xl">
                                                                <ProfileImage source="imageUrl" />
                                                            </div>
                                                            <div className="media-body pl-5">
                                                                <div className="font-weight-bolder mb-0">
                                                                    <span className="font-weight-bold">Name: </span><TextField source="name" label="Name" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Location: </span><TextField source="ambassadorLocationType" label="Location" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Gender: </span><TextField source="ambassadorCriteriaGender" label="Gender" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Age: </span><TextField source="ambassadorCriteriaAgeFrom" label="From" /> - <TextField source="ambassadorCriteriaAgeTo" label="To" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Amount: </span><TextField source="amount" label="Amount" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Gig Price: </span><TextField source="gigPrice" label="Gig Price" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Status: </span> <CampaignStatus source="campaignStatus" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="media">
                                                            <div className="avatar avatar-xl">
                                                                <ProfileImage source="brand.logoUrl" />
                                                            </div>
                                                            <div className="media-body pl-5">
                                                                <div className="font-weight-bold mb-0">
                                                                    <span className="font-weight-bold">Brand Info </span>
                                                                </div>
                                                                <div className="font-weight-bolder mb-0">
                                                                    <span className="font-weight-bold">Name: </span><TextField source="brand.brandName" label="Name" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Email: </span><TextField source="brand.email" label="Email" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Title: </span><TextField source="brand.jobTitle" label="Title" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <span className="font-weight-bold">Summary: </span><TextField source="brand.summary" label="Summary" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" >
                                        <TabbedShowLayout>
                                            <Tab label="Invites" >
                                                <ArrayField source="invites" label="">
                                                    <SingleFieldList linkType={false}>
                                                        <section className="">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <Invite source="inviteStatus" clickable={false} />
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </SingleFieldList>
                                                </ArrayField>

                                            </Tab>
                                            <Tab label="Gigs" >
                                                <ArrayField source="gigs" label="">
                                                    <SingleFieldList linkType={false}>
                                                        <section className="">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <Gig source="gigName" clickable={false} />
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </SingleFieldList>
                                                </ArrayField>
                                            </Tab>

                                            <Tab label="Submissions" >
                                                <ArrayField source="submissions" label="">
                                                    <SingleFieldList linkType={false}>
                                                        <section className="">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <Submission source="submissionStatus" clickable={false} />
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </SingleFieldList>
                                                </ArrayField>
                                            </Tab>
                                            <Tab label="categories">
                                                <ArrayField source="categories" label="">
                                                    <SingleFieldList linkType={false}>
                                                        <Category source="name" />
                                                    </SingleFieldList>
                                                </ArrayField>
                                            </Tab>
                                        </TabbedShowLayout>
                                    </div>
                                </div>
                            </SimpleForm>
                        </SimpleShowLayout>
                    </Show>
                </Col>
            </Row>
        </Container>
    )
}

export default Campaign