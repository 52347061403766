
import React, {useEffect} from 'react';
import { useAuthenticated } from 'react-admin';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';


const MyUserMenu = (props) => {
    useAuthenticated()
        const fetchProfile = () => {
        crudGetOne(
            // The resource
            'settings',
            // The id of the resource item to fetch
            '123',
            // The base path. Mainly used on failure to fetch the data
            '/settings',
            // Whether to refresh the current view. I don't need it here
            false
        );
    };
    useEffect(async () => {
        await fetchProfile();
    }, []);

return (
    <UserMenu label={'Settings'} {...props} className="pull-right">
        <MenuItemLink
            to="/settings"
            primaryText="settings"
            leftIcon={<SettingsIcon />}
        />
    </UserMenu>
);
}
export default MyUserMenu;
