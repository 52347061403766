import React, { useState, useEffect } from 'react';
import { Create, SimpleForm, Loading, Error, useAuthenticated, CheckboxGroupInput, SelectInput, required, SaveButton, Toolbar, TopToolbar,TextInput, FormDataConsumer, ListButton,useRedirect, useDataProvider } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SendIcon from '@material-ui/icons/Send';
import {  toast } from 'react-toastify';

const PostShowActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
             <ListButton label="BACK" basePath={basePath} record={data} className="btn btn-link btn-xs mr-2" icon={<ChevronLeft />} />
        </TopToolbar>
    )
};

const CreateInvite = ({ staticContext, ...props }) => {
    useAuthenticated()
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const UserEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton className="btn btn-primary btn-lg btn-pill m-3 " onSave={handleSubmit} label="Send" icon={<SendIcon />} />
        </Toolbar>
    );
    const handleSubmit = async values => {

        console.log(values)
            dataProvider.create('invites', values).then(({ data }) => {
                console.log(data)
                toast.success("User invite send successfully");
                redirect('list', props.basePath);
            })
            .catch(error => {
                toast.error(error);
            });
       
    };
    return (
        <Container fluid >
            <h3><PersonAddIcon /> User Invite</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <div className="col-12 mt-6">
                        <div className="shadow-xl rounded">


                            <Create actions={<PostShowActions />}
                                id="123"
                                resource="invites"
                                basePath="/invites"
                                title="Invite User"
                                redirect={false} // I don't need any redirection here, there's no list page
                                {...props}
                            >
                                <SimpleForm toolbar={<UserEditToolbar />} warnWhenUnsavedChanges>
                                    <div className="card col-12">

                                        <div className="card-body col-12">
                                            <div className="row m-0">
                                                <div className="col-md-12 ">
                                                    <p className="card-title">User Invite </p>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-6 mb-3">
                                                    <TextInput className="form-control " type="text" label="First Name" source="firstName" validate={[required()]} />
                                                </div>
                                                <div className="col-md-6 mb-3">

                                                <TextInput className="form-control " type="text" label="Last Name" source="lastName" />

                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-12 mb-3">
                                                    <TextInput className="form-control " type="email" label="Email Address" source="email" validate={[required()]} />
                                                </div>
                                            </div>
                                           
                                            <div className="row m-0">
                                                <div className="col-md-12 mb-3">
                                                    <SelectInput className="form-control " source="role" label="App Type" choices={[
                                                        { id: '', name: 'Choose the App...' },
                                                    { id: 'AMBASSADOR', name: 'Ambassador' },
                                                    { id: 'BRAND_MANAGER', name: 'Brand' },
                                                    ]} validate={[required()]}/>
                                                </div>
                                            </div>
                                            <FormDataConsumer>
                                            {({ formData, ...rest }) => formData.role === 'BRAND_MANAGER' &&
                                            <div className="row m-0">
                                                <div className="col-md-12 mb-3">
                                                    <TextInput className="form-control " type="text" label="Brand Name" source="brandName" validate={[required()]} />
                                                </div>
                                            </div>
                                             }
                                             </FormDataConsumer>
                                            <div className="row m-0">
                                                <div className="col-md-12 mb-3">
                                                    <TextInput className="form-control " type="text" label="Please provide your website/social media link" source="instagramAccount"  />
                                                </div>
                                            </div>
                                            <div className="row m-0 ">
                                                <div className="col-md-12 ">
                                                <CheckboxGroupInput className="form-control " label="Invite Options" source="options" choices={[
                                                        { id: 'approved', name: 'Approved' },
                                                        { id: 'signup', name: 'Send Invite Email' },
                                                    ]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SimpleForm>
                            </Create>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};

export default CreateInvite