
import { useAuthenticated } from 'react-admin';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { approveUser, changeUserStatus, activeAwayStatus, userEnableStatus } from '../../RestAPI'
import { Show, SimpleShowLayout, TextField, TopToolbar,DateField, ImageField, SimpleForm, useRecordContext,useRefresh,NumberField, TabbedShowLayout, Tab, ListButton,Datagrid,ArrayField, SingleFieldList} from 'react-admin';
import UserIcon from '@material-ui/icons/Person';
import MobileIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import ReactPlayer from 'react-player'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import  {useState, Fragment, useEffect} from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MapIcon from '@material-ui/icons/Language';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import LocationIcon from '@material-ui/icons/LocationOn';
import keyBy from 'lodash/keyBy';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import {  List, Filter, SearchInput } from 'react-admin';
import UpdateLocationAction from "../../utils/UpdateLocationAction"
import CustomPaymentStatusButton from "../../utils/CustomPaymentStatusButton"
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
    arrow: {
      color: theme.palette.common.black
    },
    tooltip: {
      backgroundColor: theme.palette.common.black
    }
  }));

  function BlackTooltip(props) {
    const classes = useStyles();
  
    return <Tooltip arrow classes={classes} {...props} />;
  }

const TagFilter = (props) => (
    <Filter {...props}>
      <SearchInput source="q" resettable alwaysOn />
    </Filter>
  )
const VideoPlayer = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<span className="font-weight-bold">Not Applicable</span>)
    } else {
        if (record && record[source]) {
            return (<ReactPlayer url={record && record[source]} controls={true} />);
        } else {
            return (<span className="font-weight-bold">No Video Resume</span>)
        }
    }


};

const ProfileImage = ({ source }) => {
    const record = useRecordContext();
    console.log(record)
    // if(record.role === "BRAND_MANAGER") {
    //     if(record.brand?.logoUrl) {
    //         return (<ImageField source="profileImageS3Url" className="avatar__img rounded-circle border border-width-4" />);
    //     } else {
    //         return (<img src="/assets/img/logo/user-512.png" alt="Profile" className="avatar__img rounded-circle border border-width-4" />);
    //     }
    // } else {
        if (record && record[source]) {
            return (<ImageField source="profileImageUrl" className="avatar__img rounded-circle border border-width-4" />);
        } else {
            return (<img src="/assets/img/logo/user-512.png" alt="Profile" className="avatar__img rounded-circle border border-width-4" />);
        }
    // }
    
};

const UserRating = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        if (record.brand?.averageRating) {
            return (<span className="font-weight-normal mb-0">Rating: <img src="/assets/img/logo/Actions-rating-icon.png" alt="Rating" className="rating-img " /> <span className="font-weight-normal mb-0" >{record.brand.averageRating}</span> </span>);
        } else {
            return (<span className="font-weight-normal mb-0">Rating: <img src="/assets/img/logo/Actions-rating-icon-gray.png" alt="Rating" className="rating-img " /> <span className="font-weight-normal mb-0" >0</span> </span>);
        }
    } else {
        if (record && record[source]) {
            return (<span className="font-weight-normal mb-0">Rating: <img src="/assets/img/logo/Actions-rating-icon.png" alt="Rating" className="rating-img " /> <span className="font-weight-normal mb-0" >{record[source]}</span> </span>);
        } else {
            return (<span className="font-weight-normal mb-0">Rating: <img src="/assets/img/logo/Actions-rating-icon-gray.png" alt="Rating" className="rating-img " /> <span className="font-weight-normal mb-0" >0</span> </span>);
        }
    }
};
const ProfileStatus = ({ source }) => {
    const record = useRecordContext();
    if (record) {
        if (record.isDeleted || record.deletedOn) {
            return <span className="badge badge-pill badge-danger">Deleted</span>
        } else if (record.isApproved) {
            return <span className="badge badge-pill badge-success">Approved</span>
        } else if (record.isActive) {
            return <span className="badge badge-pill badge-info">Active</span>
        } else {
            return <span className="badge badge-pill badge-warning">Away</span>
        }
    } else {
        return (<span className="badge badge-pill badge-warning">Away</span>);
    }
};

const UserAddress = ({ source }) => {
    const record = useRecordContext();
    if (record && record[source]) {
        const addresses = record[source].map((address) => {
            if (!address.isDeleted) {
                return (
                    <>
                        <div className="font-weight-normal mb-0">{address.lineOne}</div>
                        <div className="font-weight-normal mb-0">{address.lineTwo}</div>
                        <div className="font-weight-normal mb-0">{address.city}</div>
                        <div className="font-weight-normal mb-0">{address.state} {address.zipCode}</div>
                        <div className="font-weight-normal mb-0">{address.country}</div>
                    </>
                );
            }
            return (<span className=""></span>);
        }
        );
        return (addresses);
    } else {
        return (<span className=""></span>);
    }
};

const Facebook = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        if(record.brand?.facebook) {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" onClick={() => window.open('https://www.facebook.com/' + record.brand.facebook)} >
            <FacebookIcon className="text-facebook"/>
        </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <FacebookIcon className="text-facebook"/>
        </button>);
        }
    } else {
        if (record && record[source]) {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" onClick={() => window.open('https://www.facebook.com/' + record[source])} >
                <FacebookIcon className="text-facebook"/>
            </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
                <FacebookIcon className="text-facebook"/>
            </button>);
        }
    }
    
};

const Instagram = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        if(record.brand?.instagram) {
        return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.instagram.com/' + record.brand.instagram)}>
        <InstagramIcon className="text-instagram"/>
    </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <InstagramIcon className="text-instagram"/>
        </button>);
        }
    } else {
        if (record && record[source]) {
            return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.instagram.com/' + record[source])}>
            <InstagramIcon className="text-instagram"/>
        </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <InstagramIcon className="text-instagram"/>
        </button>);
        }
    }
};

const YouTube = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        let url = ''
        if(record.brand?.website) {
            if (record.brand.website.includes('http://') || record.brand.website.includes('https://')) {
                url = record.brand.website;
            } else {
                url = 'http://' + record.brand.website;
            }
        return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open(url)}>
        <MapIcon className="text-facebook"/>
    </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <MapIcon className="text-facebook" />
        </button>);
        }
    } else {
        if (record && record[source]) {
            return (<button className="btn btn-light iconbox iconbox-xs  mr-1" onClick={() => window.open('https://www.youtube.com/c/' + record[source])}>
            <YouTubeIcon className="text-youtube"/>
        </button>);
        } else {
            return (<button className="btn btn-light iconbox iconbox-xs mr-1" disabled >
            <YouTubeIcon className="text-youtube" />
        </button>);
        }
    }
};

const CampaignName = ({ source }) => {
    const record = useRecordContext();
    if (record ) {
        if(record.role === "BRAND_MANAGER") {
            return (
                <span style={{ alignItem: 'center' }}>
                    <img src={record.imageUrl ? record.imageUrl : '/assets/img/logo/user-512.png'} className="avatar__img xs_profile_image rounded-circle border border-width-4" />
                    <span className="ml-2">{record.name}</span>
                </span>
                );
        } else {
            return (
                <span style={{ alignItem: 'center' }}>
                    <img src={record.campaign?.imageUrl ? record.campaign?.imageUrl : '/assets/img/logo/user-512.png'} className="avatar__img xs_profile_image rounded-circle border border-width-4" />
                    <span className="ml-2">{record.campaign?.name}</span>
                </span>
                );
        }

    } else {
        return (<>
        <span>{source}</span>
        </>);
    }
};

const PaymentStatus = ({ source }) => {
    const record = useRecordContext();
    if (record) {
        if(record.role === "BRAND_MANAGER") {
            if (record.paymentStatus === "Initiated") {
                return <span className="text-warning"><CloseIcon className="danger"/> {record.paymentStatus}</span>
            } else {
                return <span className="text-success"><CheckIcon className="success" /> {record.paymentStatus}</span>
            }
        } else {
            if (record.payoutStatus === "Paid") {
                return <span className="text-success"><CheckIcon className="success" /> Paid</span>
            } else {
                return <span className="text-warning"><CloseIcon className="danger"/> Not Paid</span>
            }
        }

    } else {
        return (<span className="text-warning"><CloseIcon className="danger"/> Not Paid</span>);
    }
};

const PostBulkActionButtons = props => (
    <Fragment>
        <CustomPaymentStatusButton label="Payment Update" {...props} />
    </Fragment>
);

const Payment = ({ source }) => {
    const record = useRecordContext();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'id', order: 'ASC' })
    console.log(record)
    if (record && record.payment && record.payment.length > 0) {

        let fakeProps = {
            basePath: "/brands",
            hasCreate: false,
            hasEdit: false,
            hasList: true,
            hasShow: false,
            history: {},
            location: { pathname: "/", search: "", hash: "", state: undefined },
            match: { path: "/", url: "/", isExact: true, params: {} },
            options: {},
            permissions: null,
            resource: "payments"
          }

          return (<Fragment>
            <List {...fakeProps} filters={<TagFilter />}  filter={{ userId:record.id}} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} exporter={false} >
            <Datagrid >
                <TextField source="id" label="Id"/>
                <CampaignName source="name" label="Campaign Name" />
                <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} className='test-left' label="Amount"/>
                <TextField source="stripeReferenceId" label="Stripe Reference" />
                <PaymentStatus source="paymentStatus" label="Payment Status" />
                <DateField source="createdOn" label="Created On" showTime/>
            </Datagrid>
            </List>
            
        </Fragment>);
    } if (record && record.earnings && record.earnings.length > 0) {
        let fakeProps = {
            basePath: "/ambassadors",
            hasCreate: false,
            hasEdit: false,
            hasList: true,
            hasShow: false,
            history: {},
            location: { pathname: "/", search: "", hash: "", state: undefined },
            match: { path: "/", url: "/", isExact: true, params: {} },
            options: {},
            permissions: null,
            resource: "earnings"
          }
        return (<Fragment>
            <List {...fakeProps} filters={<TagFilter />}  filter={{ userId:record.id}} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} exporter={false} >
            <Datagrid >
                <TextField source="id" label="Id"/>
                <CampaignName source="campaign.name" label="Campaign Name" />
                <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} className='test-left' label="Amount"/>
                <TextField source="stripeReferenceId" label="Stripe Reference" />
                <PaymentStatus source="paymentStatus" label="Payment Status" />
                <DateField source="createdOn" label="Created On" showTime/>
            </Datagrid>
            </List>
            
        </Fragment>);
    } else {
        return (<h5>No Payments</h5>)
    }
};


const Category = ({ source }) => {
    const record = useRecordContext();
    try {
        return (<span className="badge badge-soft-info ml-2">{record[source]}</span>);
    } catch (e) {
        console.error(e)
    }
};

const Summary = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<TextField source="brand.summary" label="Summary" />)
    } else {
        return (<TextField source="summary" label="Summary" />)
    }
}

const StripeAccount = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<TextField source="stripeCustomerId" />)
    } else {
        return (<TextField source="stripeAccountId" />)
    }
}

const UserName = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<><TextField className="font-weight-bolder mb-0" source="brand.brandName" /><br /><TextField className="font-weight-bolder mb-0" source="firstName" label="First Name" /> <TextField className="font-weight-bolder mb-0" source="lastName" label="Last Name" /><br /><TextField className="font-weight-normal mb-0" source="brand.jobTitle" label="Job Title" /></>)
    } else {
        return (<><TextField className="font-weight-bolder mb-0" source="firstName" label="First Name" /> <TextField className="font-weight-bolder mb-0" source="lastName" label="Last Name" /></>)
    }
}

const EmailAddress = ({ source }) => {
    const record = useRecordContext();
    if (record && record[source]) {
        if(record.role === "BRAND_MANAGER") {
            return (<><EmailIcon /> <TextField source="brand.email" label="Email" /></>)
        } else {
            return (<><EmailIcon /><TextField source="email" label="Email" /></>)
        }
    } else {
        return (<span className="font-weight-normal mb-0" ></span>)
    }
}

const CampaignCount = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<span className="font-weight-normal mb-0" > {record.brand?.campaignCount} </span>)
    } else {
        return (<span className="font-weight-normal mb-0" >  {record.campaigncount} </span>)
    }
}

const GigCount = ({ source }) => {
    const record = useRecordContext();
    if(record.role === "BRAND_MANAGER") {
        return (<span className="font-weight-normal mb-0" > {record.brand?.gigCount} </span>)
    } else {
        return (<span className="font-weight-normal mb-0" >  {record.gigcount} </span>)
    }
}

const MobileNumber = ({ source }) => {
    const record = useRecordContext();
    if (record && record[source]) {
        return (<><MobileIcon /> <TextField source="mobileNumber" /></>)
    } else {
        return (<span className="font-weight-normal mb-0" ></span>)
    }
}

const UserShowActions = ({ basePath, data, resource }) => {
    const myrefresh = useRefresh();
    const activateUser = async () => {
        let userData = await changeUserStatus(data);
        if (userData && userData.statusCode < 300) {
            toast.success("User updated successfully");
            myrefresh()
        } else {
            toast.error("Status code:" + userData.statusCode + ", " + userData.message);
        }
    }
    const approveResume = async () => {
        let userData = await approveUser(data);
        if (userData && userData.statusCode < 300) {
            toast.success("User updated successfully");
            myrefresh()
        } else {
            toast.error("Status code:" + userData.statusCode + ", " + userData.message);
        }
    }
    const updateStatus = async () => {
        let userData = await activeAwayStatus(data);
        if (userData && userData.statusCode < 300) {
            toast.success("User updated successfully");
            myrefresh()
        } else {
            toast.error("Status code:" + userData.statusCode + ", " + userData.message);
        }
    }
    const enableStatus = async () => {
        let userData = await userEnableStatus(data);
        if (userData && userData.statusCode < 300) {
            toast.success("User updated successfully");
            myrefresh()
            
        } else {
            toast.error("Status code:" + userData.statusCode + ", " + userData.message);
        }
    }

    return (
        <TopToolbar>
             <ListButton label="BACK" basePath={basePath} record={data} className="btn btn-link btn-xs mr-2" icon={<ChevronLeft />} />
            <button type="button" className="btn btn-outline-info btn-xs" onClick={activateUser} >
                {data && !data.deletedOn && (
                    <>  <ToggleOffIcon className="text-danger" /> INACTIVATE </>
                )}
                {data && data.deletedOn && (
                    <>  <ToggleOnIcon className="text-success" /> ACTIVATE </>
                )}
            </button>
            <button type="button" className="btn btn-outline-info btn-xs ml-2" onClick={enableStatus} disabled={data && data.deletedOn}>
                {data && !data.isEnabled && (
                    <>  <ToggleOffIcon className="text-danger" /> ENABLE</>
                )}
                {data && data.isEnabled && (
                    <>  <ToggleOnIcon className="text-success" /> DISABLE </>
                )}
            </button>
            <button type="button" className="btn btn-outline-info btn-xs ml-2" onClick={approveResume} disabled={data && data.deletedOn}>
                {data && !data.isApproved && (
                    <>  <ToggleOnIcon className="text-success" /> APPROVE</>
                )}
                {data && data.isApproved && (
                    <>  <ToggleOffIcon className="text-danger" /> REJECT </>
                )}
            </button>
            { data && data.role !== 'BRAND_MANAGER' && (
                <>
                    <button type="button" className="btn btn-outline-info btn-xs ml-2" onClick={updateStatus} disabled={data && data.deletedOn}>
                        {data && !data.isActive && (
                            <>  <ToggleOffIcon className="text-danger" /> SET ACTIVE</>
                        )}
                        {data && data.isActive && (
                            <>  <ToggleOnIcon className="text-success" /> SET AWAY </>
                        )}
                    </button>
                </>
            )}
        </TopToolbar>
    )
};

const User = (props) => {
    useAuthenticated()
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [role, setRole] = useState("");
    const [roleName, setRoleName] = useState("");
    const [roleIcon, setRoleIcon] = useState(null);
    let fakeProps = {
        basePath: "/ambassadors",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "campaigns"
      }
    useEffect( () => {
        console.log(props);
        
        if(props.resource === "brands") {
            setRole("BRAND_MANAGER")
            setRoleName("Brand")
            setRoleIcon(<BusinessCenter />)
        } else {
            setRoleName("Ambassador")
            setRole("AMBASSADOR")
            setRoleIcon(<UserIcon />)
        }
        let record = props.record;
        if (record && record.addresses && record.addresses.length > 0) {
            setLatitude( parseFloat( record.addresses[0].latitude));
            setLongitude( parseFloat(record.addresses[0].longitude));

        }
      },[]);
      const AddressLocation =({ props }) => {
        const record = useRecordContext();
        let latitude = 0;
        let longitude = 0;
        console.log(record);
        if (record && record.addresses && record.addresses.length > 0) {
            latitude = parseFloat( record.addresses[0].latitude);
            longitude = parseFloat(record.addresses[0].longitude);

        }
        let URL = 'http://maps.google.com/maps?q=' + latitude + 'N+' + Math.abs(longitude) + 'W+(label)&ll='+latitude+','+longitude+'&spn=0.004250,0.011579&t=h&iwloc=A&hl=en';
        console.log(URL)
        if (latitude !== 0) {
            return ( 
              
            <div className="row">
                <div className="col col-md-12 col-lg-12 col-sm-12 mr-0" ><span className="font-weight-bold">Location: </span><span className="danger">
                <BlackTooltip title="Click here to view in Google map" aria-label="View in Map" placement="top" arrow>
                    <a href={URL} target="_blank"><LocationIcon /></a> 
                </BlackTooltip>
                </span> 
                <span>Lat: <strong>{latitude}</strong>, Lng: <strong>{longitude}</strong></span>
                <UpdateLocationAction record={record}/>
                </div>
            </div>
           
            );
        } else {
            return (<div className="row">
            <div className="col col-md-12 col-lg-12 col-sm-12 mr-0" ><span className="font-weight-bold">Location: </span><span className="danger">
           <LocationIcon />
            </span> 
            <span>No Coordinates</span>
            
            </div>
        </div>);
        }
    };
      const Campaign = ({ ids, data, basePath }) => (
        <div className="container-fluid">
        <div className="row" style={{ margin: '1em', paddingRight:'20px' }} >
          {ids.map(id =>
            <div className="col col-md-3 col-lg-2 col-sm-4 mb-5 " key={id}>
              <div className={`card w-100 h-100 shadow invite-card-cursor `} >
                <div className="text-center " >
                  <div className=" py-2 px-2 "><div style={{ alignItem: 'center' }}><img src={data[id]?.imageUrl ? data[id]?.imageUrl : '/assets/img/logo/user-512.png'} className="avatar__img small_profile_image rounded-circle border border-width-4" /></div>
                  <h4 className="mt-3 mb-2">{data[id]?.name}</h4>
                    <div className="font-weight-lighter">Location: {data[id]?.ambassadorLocationType}</div>
                    <div className="font-weight-lighter">Gender: {data[id]?.ambassadorCriteriaGender}</div>
                    <div className="font-weight-lighter">Age: {data[id]?.ambassadorCriteriaAgeFrom} - {data[id]?.ambassadorCriteriaAgeTo}</div>
                    <div ><span className="badge badge-pill badge-info font-weight-light">{data[id]?.campaignStatus}</span></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
      );
    return (
        <Container fluid >
            <h3>{roleIcon} {roleName}</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">

                    <Show actions={<UserShowActions />} {...props} title="User" style={{ minWidth: '85em'}}>
                        <SimpleShowLayout>
                            <SimpleForm toolbar={false}>
                                <div className="row ">
                                    <div className={`${role === 'AMBASSADOR' ? "col-lg-8" : "col-lg-12"}`} >
                                        <Card  className=" rounded">

                                            <Card.Body >
                                                <Card.Title>{roleName} Info</Card.Title>
                                                <div className="row" >
                                                    <div className="col-lg-6" >
                                                        <div className="media">
                                                            <div className="avatar avatar-xl">
                                                                <ProfileImage source="profileImageUrl" />
                                                            </div>
                                                            <div className="media-body pl-5">
                                                                <div className="font-weight-bolder mb-0">
                                                                    <UserName className="font-weight-bolder mb-0" source="firstName" label="First Name" /> 
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <EmailAddress source="email" label="Email" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <UserAddress source="addresses" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <MobileNumber source="mobileNumber" />
                                                                </div>
                                                                <div className="font-weight-normal">
                                                                    <UserRating source="averageRating" />
                                                                </div>
                                                                <ul className="list-inline">
                                                                    <li className="list-inline-item">
                                                                        <strong ><CampaignCount source="campaignCount" className="font-weight-bolder" label="" /></strong>
                                                                        <span> Campaign</span>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <strong ><GigCount source="gigCount" className="font-weight-bolder" label="" /></strong>
                                                                        <span> Gigs</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="font-weight-normal">
                                                            <span className="font-weight-bold">Role: </span><TextField source="role" label="Role" />
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            <span className="font-weight-bold">Status: </span> <ProfileStatus source="status" />
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            <span className="font-weight-bold">Stripe Account: </span> <StripeAccount source="stripeAccountId" />
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            <Facebook source="facebookAccount" /> <Instagram source="instagramAccount" /> <YouTube source="youtubeUrl" />
                                                        </div>
                                                        <p className="font-weight-normal">
                                                            <span className="font-weight-bold">Summary: </span> <Summary source="summary" label="Summary" />
                                                        </p>
                                                        {role === 'AMBASSADOR' && (
                                                        <div className="font-weight-normal">
                                                            <AddressLocation props={props} source="summary" />
                                                        </div>
                                                         )}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    {role === 'AMBASSADOR' && (
                                        <div className="col-lg-4">
                                        <Card className=" rounded video">
                                            <Card.Body>
                                                <Card.Title>Video Resume</Card.Title>
                                                <VideoPlayer source="videoResumeUrl" label="Video Resume" />
                                            </Card.Body>
                                        </Card>
                                        </div>
                                    )}
                                   
                                </div>
                                <div className="row">
                                    <div className="col" >
                                        <TabbedShowLayout>
                                        <Tab label="campaigns">
                                                <div className="invites">
                                                <List {...fakeProps} filters={<TagFilter />} filter={{ userId:props.id, role: props.resource}} sort={{ field: 'id', order: 'DESC'}} bulkActionButtons={false} exporter={false} className="p-2">
                                                    <Campaign />
                                                </List>
                                                </div>
                                            </Tab>
                                            <Tab label="Payment History" >
                                                <Payment />
                                            </Tab>
                                            <Tab label="categories">
                                                <ArrayField source="categories" label="">
                                                    <SingleFieldList linkType={false}>
                                                        <Category source="name"/>
                                                    </SingleFieldList>
                                                </ArrayField>
                                            </Tab>

                                           


                                        </TabbedShowLayout>
                                    </div>
                                </div>
                            </SimpleForm>
                        </SimpleShowLayout>
                    </Show>
                </Col>
            </Row>
        </Container>
    )
}

export default User