
import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField, TextInput, useRecordContext, CreateButton, ExportButton, TopToolbar } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CustomDeleteAction from "../../utils/CustomDeleteAction"
import CustomApproveAction from "../../utils/ApproveActionDialog"
import CustomResendEmailAction from "../../utils/CustomResendEmailAction"

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const CampaignStatus = ({ source }) => {
    const record = useRecordContext();
    try {
     
    if(record.isDeleted || record.deletedOn) {
        return <span className="text-danger"><CloseIcon /> Deleted</span>
    } else if(record.status === 'Approved') {
        return <span className="text-success"><CheckIcon /> Approved</span>
    } else {
        return <span className="text-warning"><PauseIcon /> Created</span>
    } 
    }catch(e) {
        console.error(e)
    }
};
const InviteListActions = props => (
    <TopToolbar {...props} >
        <CreateButton basePath={props.basePath} label="Invite" icon={<PersonAddIcon />}/>
        <ExportButton />
    </TopToolbar>
);

const Invites = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><NotificationsActiveIcon /> Invites</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <List {...props} filters={postFilters} bulkActionButtons={false} actions={<InviteListActions />}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="firstName" label="First Name" />
                            <TextField source="lastName" label="Last Name" />
                            <TextField source="email" label="Email" />
                            <TextField source="role" label="Role" />
                            <CampaignStatus source="isActive" label="Status"/>
                            <DateField source="createdOn" label="Created On" showTime/>
                            <CustomDeleteAction />
                            <CustomApproveAction/>
                            <CustomResendEmailAction />
                        </Datagrid>
                    </List>
                </Col>
            </Row>
        </Container>
    )
}

export default Invites