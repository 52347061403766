import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField, TextInput, useRecordContext } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import VideocamIcon from '@material-ui/icons/VideoLibrary';
import CustomDeleteAction from "../../utils/CustomDeleteAction"
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const CampaignStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record.deletedOn) {
        return (<><CloseIcon className="danger"/> Deleted </>);
    } else {
        return (<> <CheckIcon className="success" /> Active</>);
    }    
    }catch(e) {
        console.error(e)
    }
};
const Gigs = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><VideocamIcon /> Gigs</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <List {...props} filters={postFilters} bulkActionButtons={false}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="gigName" label="Name" />
                            <TextField source="gigDescription" label="Description" />
                            <TextField source="productDelivery" label="Delivery" />
                            <TextField source="gigType" label="Type" />
                            <TextField source="campaign.name" label="Campaign Name" />
                            <TextField source="giveawayType" label="Giveaway Type"/>
                            <TextField source="giveawayValue" label="Giveaway Value"/>
                            <TextField source="productGiveaway" label="Product Giveaway"/>
                            <TextField source="productGiveawayValue" label="Product Giveaway Value"/>
                            <CampaignStatus source="deletedOn" label="Status" />
                            <DateField source="createdOn" label="Created On" showTime/>
                            <CustomDeleteAction />
                        </Datagrid>
                    </List>
                </Col>
            </Row>
        </Container>
    )
}

export default Gigs