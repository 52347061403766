// Endpoint for development
// const ENDPOINT = 'http://brandbass-api-internal-1374418072.us-east-1.elb.amazonaws.com';

// Endpoint for Staging
const ENDPOINT = 'https://api-stg.brandbass.ai';

// Endpoint for local
//  const ENDPOINT = 'http://localhost:3000';

// PROD 
// const ENDPOINT = 'https://api.brandbass.ai';

//const ENDPOINT = process.env.REACT_APP_API_BASEURL

const toObject = (arr, key) => arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

async function apiFetch(route, verb, params = {}, body){
    params.role = "SUPER_ADMIN"; // TO DO : change to ADMIN
    const jwt = localStorage.getItem('jwt'); // the token which is received on successful login
    const finalRoute = route + '?' + serialize(params); // endpoint to call
    console.log(finalRoute)
    // options for the Fetch api
    const options = {
        method: verb,
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer '+jwt
        }
    }

    // include body only for api calls that are not of type GET.
    if(verb.toLowerCase() !== 'get') options.body = body ? JSON.stringify(body) : ''
    localStorage.setItem('isLoading',true);
    console.log(options)
    return fetch(finalRoute, options)
        .then(response => response.json())
        .then(async responseJSON => {
            console.log(responseJSON)
            if(responseJSON.statusCode === 401 &&  responseJSON.message === 'Unauthorized'){
                localStorage.removeItem('isLoading');
                return;
            } else if(responseJSON.status === 404 ){
                localStorage.removeItem('isLoading');
                console.log(responseJSON)
                return responseJSON;
            } else {
                // include a 'total' key with length of the data array to have it compatible with React-Admin data-provider structure expectations
                responseJSON.total = responseJSON.data && responseJSON.data.length > 0 ? responseJSON.data.length : null
                localStorage.removeItem('isLoading');
                return responseJSON;
            }
        })
        .catch(error => {
            console.error(error)
            localStorage.removeItem('isLoading');
            return error;
        });   
}


export function getFileUploadSignedUrl(fileData, type){
    return apiFetch(`${ENDPOINT}/campaign/s3SignedUrlForUpload`, 'POST', {type}, fileData);
}

export function authenticate(signin){
    if(signin.userIdentifier && signin.userPassword){
        return apiFetch(`${ENDPOINT}/authentication/validate`,'POST', {}, signin);
    }
}

export function checkUsernameAvailability(username){
    if(username){
        return apiFetch(`${ENDPOINT}/authentication/checkUsername`,'GET',{username});
    }
}

export function checkEmailAvailability(email){
    if(email){
        return apiFetch(`${ENDPOINT}/authentication/checkUserEmail`,'GET',{email});
    }
}

export function checkClientVersion(){
    return apiFetch(`${ENDPOINT}/clientVersion`,'GET');
}

export function signUpUser(authentication){
    return apiFetch(`${ENDPOINT}/authentication/register`,'POST',{}, authentication);
}

export function confirmUser(authentication){
    return apiFetch(`${ENDPOINT}/authentication/confirmRegistration`,'POST',{}, authentication);
}

export function saveUser(userId, user){
    return apiFetch(`${ENDPOINT}/user/${userId}`,'PATCH',{},user);
}

export function forgotPassword(userName){
    return apiFetch(`${ENDPOINT}/authentication/forgotPassword`,'GET',{userName});
}

export function getIssues(params){
    return apiFetch(`${ENDPOINT}/issue/findAllForAdmin`, 'POST',{},params).then(function(response){ return  response.data  });
}

export function getIssueReasons(type){
    return apiFetch(`${ENDPOINT}/issue/reasons`, 'GET', {type})
}

export function saveIssue(issue){
    return apiFetch(`${ENDPOINT}/issue`,'POST',{}, issue);
}

export function deleteIssue(id){
    return apiFetch(`${ENDPOINT}/issue/${id}`,'DELETE');
}

export function getOneIssue(id){
    return apiFetch(`${ENDPOINT}/issue/${id}`, 'GET');
}

export function getDashboardCounts(){
    return apiFetch(`${ENDPOINT}/dashboard/counts`, 'GET').then(function(response){ return response && response.data ? response.data : response; });
}

export function getSettings(){
    return apiFetch(`${ENDPOINT}/setting`, 'GET').then(function(response){ console.log(response.data);  return response.data; });
}

export function getSetting(){
    return apiFetch(`${ENDPOINT}/setting/dashboard`, 'POST',{}, {}).then(function(response){ console.log(response.data); let ss = toObject( response.data.settings, 'name');
    console.log(ss); return {
        data: { id: 123, language: 'en', settings:ss }
    } });
}

export function updateSetting(params){
    return apiFetch(`${ENDPOINT}/setting`, 'PUT',{}, params).then(function(response){ console.log(response.data);  return { data: response.data }; });
}

export function getUsers(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/all`, 'POST',{}, params).then(function(response){ return response.data; });
}

export function getBrands(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/brands`, 'POST',{}, params).then(function(response){ return response.data; });
}
export function getAmbassadors(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/ambassadors`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getEarnings(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/earnings`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getPayments(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/payments`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getUser(userId){
    console.log('userId ',userId)
    return apiFetch(`${ENDPOINT}/user/${userId}`, 'GET').then(function(response){ return  { data: response.data }; });
}

export function getDashboardUser(userId){
    console.log('userId ',userId)
    return apiFetch(`${ENDPOINT}/user/dashboard/${userId}`, 'GET').then(function(response){ return  { data: response.data }; });
}
export function approveUser(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/approve`, 'POST',{}, params).then(function(response){ return response; });
}

export function changeUserStatus(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/status`, 'POST',{}, params).then(function(response){ return response; });
}

export function activeAwayStatus(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/away`, 'POST',{}, params).then(function(response){ return response; });
}

export function userEnableStatus(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/enable`, 'POST',{}, params).then(function(response){ return response; });
}
export function getCampaigns(params){
    return apiFetch(`${ENDPOINT}/campaign/all`, 'POST',{}, params).then(function(response){ return   response.data  });
}
export function getInvites(params){
    return apiFetch(`${ENDPOINT}/user-invite/all`, 'POST',{}, params).then(function(response){ return   response.data  });
}
export function getGigs(params){
    return apiFetch(`${ENDPOINT}/campaign/allGigs`, 'POST',{}, params).then(function(response){ return   response.data  });
}

export function getFeedbacks(params){
    return apiFetch(`${ENDPOINT}/feedback/all`, 'POST',{}, params).then(function(response){ return   response.data  });
}

export function deleteCampaign(id){
    return apiFetch(`${ENDPOINT}/campaign/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function deleteGig(id){
    return apiFetch(`${ENDPOINT}/campaign/gig/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function getCampaign(campaginId){
    console.log('getCampaign ',campaginId)
    return apiFetch(`${ENDPOINT}/campaign/dashboard/${campaginId}`, 'GET').then(function(response){ return  { data: response.data }; });
}

export function inviteCampaign(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/campaign/invite`, 'POST',{}, params).then(function(response){ return  { data: response }; });
}
export function deleteInvite(id){
    return apiFetch(`${ENDPOINT}/user-invite/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function approveInvite(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/approve-user-invite`,'POST',{}, params);
}

export function resendInviteEmail(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/resend-user-invite`,'POST',{}, params);
}

export function createInvite(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/create-user-invite`,'POST',{}, params).then(function(response){ console.log(response);return   { data: response?.data };   });
}

export function locationUpdate(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/locationupdate`,'POST',{}, params);
}

export function updatePaymentStatus(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/earning/updatestatus`,'POST',{}, params).then(function(response){ return  { data: response.data }; });
}

function serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
