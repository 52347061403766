import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, Loading, Error, useAuthenticated, required, SaveButton, Toolbar, BooleanInput, NumberInput, useRecordContext,useDataProvider } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import SettingsIcon from '@material-ui/icons/Settings';
import {  toast } from 'react-toastify';




const EditSettings = ({ staticContext, ...props }) => {
    useAuthenticated()
    const dataProvider = useDataProvider();
    const PurpleTextField = ({ source }) => {
        const record = useRecordContext();
        return (<span style={{ color: 'purple' }}>{record && record[source]}</span>);
    };
    
    const UserEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton className="btn btn-primary btn-lg btn-pill m-3 " onSave={handleSubmit} />
        </Toolbar>
    );
    const handleSubmit = async values => {

        console.log(values)
            dataProvider.update('settings', values).then(({ data }) => {
                console.log(data)
                toast.success("Settings updated successfully");
            })
            .catch(error => {
                toast.error(error);
            });
       
    };
    return (
        <Container fluid >
            <h3><SettingsIcon /> App Settings</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <div className="col-12 mt-6">
                        <div className="shadow-xl rounded">


                            <Edit
                                id="123"
                                resource="settings"
                                basePath="/settings"
                                title="App Settings"
                                redirect={false} // I don't need any redirection here, there's no list page
                                {...props}
                            >
                                <SimpleForm toolbar={<UserEditToolbar />}>
                                    <div className="card col-12">

                                        <div className="card-body col-12">
                                            <div className="row m-0">
                                                <div className="col-md-12 ">
                                                    <p className="card-title">Invitation Notification Settings <PurpleTextField record={props} source="settings.InviteNotificationIterations.value.timeTo" /> </p>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-2 mb-4">
                                                    <NumberInput className="form-control " type="number" label="Time To" source="settings.InviteNotificationIterations.value.timeTo" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 mb-4">

                                                    <NumberInput className="form-control " type="number" label="Time From" source="settings.InviteNotificationIterations.value.timeFrom" placeholder="Time From" min={0} skip={1} validate={[required()]} />

                                                </div>

                                                <div className="col-md-2 mb-4">

                                                    <NumberInput className="form-control" type="number" label="Time Interval" source="settings.InviteNotificationIterations.value.timeInterval" min={0} skip={1} validate={[required()]} />

                                                </div>
                                                <div className="col-md-2 mb-4">

                                                    <NumberInput className="form-control " type="number" label="Time Gap" source="settings.InviteNotificationIterations.value.timeGapBetweenIterations" min={0} skip={1} validate={[required()]} />

                                                </div>
                                                <div className="col-md-2 mb-4">

                                                    <NumberInput className="form-control " type="number" label="Max Attempt" source="settings.InviteNotificationIterations.value.maxAttempts" min={0} skip={1} validate={[required()]} />

                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-12 ">
                                                    <p className="card-title">Reminder Settings</p>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-2 mb-4">
                                                    <NumberInput className="form-control " type="number" label="Submission Max Day" source="settings.SubmissionReminder.value.maxDays" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 mb-4">
                                                    <NumberInput className="form-control " type="number" label="Revision Max Day" source="settings.RevisionReminder.value.maxDays" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 mb-4">
                                                    <NumberInput className="form-control " type="number" label="Shipping Delay" source="settings.ShippingReminderSettings.value.delay" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-2 mb-4">
                                                    <NumberInput className="form-control " type="number" label="Match Delay" source="settings.MatchNotificationSettings.value.delay" min={0} skip={1} validate={[required()]} />
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-12 ">
                                                    <p className="card-title">Ambassador Settings</p>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-2 ">
                                                    <NumberInput className="form-control" type="number" label="Default Gig Fee" source="settings.AmbassadorFee.value.defaultGigFee" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 ">
                                                    <NumberInput className="form-control " type="number" label="Campaign Max Invite" source="settings.CampaignMaxInviteLimit.value.maxInviteLimit" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 ">
                                                    <NumberInput className="form-control " type="number" label="Campaign Limit" source="settings.AmbassadorCampaignLimit.value.maxCampaignLimit" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-2 ">
                                                    <NumberInput className="form-control " type="number" label="Campaign Distance" source="settings.CampaignDistanceLimit.value.distanceLimit" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-4 ">
                                                    <BooleanInput className="  mb-4" label="Approval Required?" source="settings.AmbassadorProfileSettings.value.AdminApprovalRequired" validate={[required()]} />
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-md-6 ">
                                                    <p className="card-title">Brand Settings</p>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <p className="card-title">Signup Settings</p>
                                                </div>
                                            </div>
                                            <div className="row m-0 ">
                                                <div className="col-md-2 ">
                                                    <NumberInput className="form-control " type="number" label="Default Gig Fee" source="settings.GigPrice.value.defaultGigPrice" min={0} skip={1} validate={[required()]} />
                                                </div>
                                                <div className="col-md-4 ">
                                                    <BooleanInput className="  mb-4" label="First Gig Free?" source="settings.GigPrice.value.firstGigFree" validate={[required()]} />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <BooleanInput className="  mb-4" label="Invite Required?" source="settings.UserInvitationSettings.value.inviteRequiredForSignup" validate={[required()]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SimpleForm>
                            </Edit>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};

export default EditSettings