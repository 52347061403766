import React, { Component } from 'react';
import {version} from '../package.json';
class Footer extends Component {

    render() {
        return (
            <footer className="site-footer">
            <section className="p-1 text-center footer_bg" >
              <p>&copy; Copyright {(new Date().getFullYear())} Brandbass, All Rights Reserved. <small>Version: {version}</small>
                </p>
            </section>
          </footer>
            );
        }
    };
    
    export default Footer;