import React, { Component, Fragment } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {  toast } from 'react-toastify';
import classnames from 'classnames';
import { translate, crudUpdate, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Button, useRefresh } from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import { resendInviteEmail } from '../RestAPI'
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  }
}));
const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
});

function BlackTooltip(props) {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}
class CustomResendEmailAction extends Component {
  state = {
    showDialog: false,
    title:null,
    button:null,
    btnClass:null,
    label:null,
    message:null,
    disablebutton:false,
    resource:null,
    isDeleted:false
  };
  componentDidMount() {
    // Changing the state after 3 sec
    let rec = this.props.record;
    // console.log(this.props)
    // console.log(rec)
    let resource = this.props.resource;
    if(!rec.deletedOn) {
      this.setState({ disablebutton: false});
        this.setState({ isDeleted: true});
    } else {
        this.setState({ isDeleted: false});
    }
     
    
 }
  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };
  
  handleApprove = async (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    console.log(this.props)
    let payload = this.props.record;
     console.log(payload)
   let data = await resendInviteEmail(payload);
   console.log(data)
   if(!data || data.error) {
    toast.error(data.error);
   } else {
    toast.success("Invite sent successfully");
   }
  

  };

  render() {
    const { showDialog } = this.state;
    const {record,  classes = {}, className } = this.props;

    return (
      <Fragment>
        <BlackTooltip title="Resend Email" aria-label="Resend Email" placement="top" arrow>
        <button onClick={this.handleClick} label={this.state.label} className={classnames('btn  iconbox iconbox-xs btn-light text-info', className)} key="button" disabled={this.state.disablebutton}>
            <SendIcon />
        </button>
        </BlackTooltip>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
          <DialogTitle>Are you sure you want to resend email to this invite?</DialogTitle>
          <DialogContent>
            <div>
            An email will be send to the user.
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleApprove} label="Send" className={classnames('text-success',  className)} key="button" >
            <SendIcon />
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

CustomResendEmailAction.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudUpdate: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool
};

CustomResendEmailAction.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudUpdate: resendInviteEmail }
  ),
  translate,
  withStyles(styles)
)(CustomResendEmailAction);