import React, {useState, useEffect} from 'react';
import { useAuthenticated } from 'react-admin';
import { Container, Row , Col, Card} from 'react-bootstrap';
import { getDashboardCounts } from '../../RestAPI'
import VerticalBar from "./VerticalBar";
import DoughnutChart from "./DoughnutChart";
import DashboardIcon from '@material-ui/icons/Dashboard';

const Dashboard = (props) => {
    useAuthenticated()
    const [dashboard, setDashboard] = useState({});
    useEffect(async () => {
        let userData = await getDashboardCounts();
        console.log(userData)
        setDashboard(userData)
    }, []);
    return <Container fluid >
        <h3><DashboardIcon /> Dashboard</h3>
        <Row className="justify-content-md-center mt-5"> 

    <Col md="auto">
    <div className="row text-center mt-6">
            <div className="col">
              <h2 className="display-md-2 text-secondary mb-0"><span className="counter" data-to="160">{dashboard ? dashboard.brandCount : 0}</span><span></span>
              </h2>
              <p>Brands</p>
            </div>
            <div className="col">
              <h2 className="display-md-2 text-secondary mb-0"><span className="counter" data-to="75">{dashboard ? dashboard.ambassadorCount : 0}</span><span></span>
              </h2>
              <p>Ambassadors </p>
            </div>
            <div className="col">
              <h2 className="display-md-2 text-secondary mb-0"><span className="counter" data-to="98">{dashboard ? dashboard.issueCount : 0}</span><span></span>
              </h2>
              <p>Issues</p>
            </div>
          </div>
        
    </Col>
   
    </Row>
        <Row className="justify-content-md-center mt-5">
    <Col md="auto">
    <div className="col-12 mt-5 text-center">
          <ul className="nav nav-pills d-inline-flex align-self-center bg-white rounded-pill shadow tab-state-primary"
            role="tablist">
            <li className="nav-item"><a className="nav-link rounded-pill px-5" href="#" data-toggle="tab"
                aria-controls="tab3_1" aria-selected="true">Today</a></li>
            <li className="nav-item"><a className="nav-link rounded-pill px-5 active" href="#" data-toggle="tab"
                aria-controls="tab3_2" aria-selected="true">Weekly</a></li>
            <li className="nav-item"><a className="nav-link rounded-pill px-5" href="#" data-toggle="tab"
                aria-controls="tab3_3" aria-selected="true">Monthly</a></li>
            <li className="nav-item"><a className="nav-link rounded-pill px-5" href="#" data-toggle="tab"
                aria-controls="tab3_4" aria-selected="true">All</a></li>
          </ul>
        </div>
    </Col>

</Row>


  <Row className="justify-content-md-center mt-5">
  <Col md="auto">
    <Card style={{ minWidth: '30rem' , minHeight:'20rem'}} className="shadow-xl rounded">

  <Card.Body>
    <Card.Title>Brand Gig Status</Card.Title>
    <Card.Text>
    <VerticalBar />
    </Card.Text>

  </Card.Body>
</Card>
    </Col>
    <Col md="auto">
    <Card style={{ minWidth: '30rem' , minHeight:'20rem'}} className="shadow-xl rounded">

  <Card.Body>
    <Card.Title>Ambassador Gig Status</Card.Title>
    <Card.Text>
    <VerticalBar />
    </Card.Text>

  </Card.Body>
</Card>
    </Col>


      </Row>
      <Row className="justify-content-md-center mt-5">
    <Col md="auto">
    <Card style={{ minWidth: '30rem' , minHeight:'20rem'}} className="shadow-xl rounded">

  <Card.Body>
    <Card.Title>Invite Status</Card.Title>
    <Card.Text>
    <DoughnutChart />
    </Card.Text>

  </Card.Body>
</Card>
    </Col>
  </Row>
    </Container>
}

export default Dashboard