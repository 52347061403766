import * as React from 'react';
import { memo, useMemo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record, useResourceContext } from 'ra-core';

import  { Button, ButtonProps } from 'react-admin';


const CustomPureShowButton = (props) => {
  const {
      basePath = '',
      icon = defaultIcon,
      label = 'ra.action.show',
      record,
      scrollToTop = true,
      ...rest
  } = props;
  const resource = useResourceContext();
  return (
      <Link
          className="btn  iconbox iconbox-xs btn-light text-info"
          to={useMemo(
              () => ({
                  pathname: record
                      ? `${linkToRecord(
                            basePath || `/${resource}`,
                            record.id
                        )}/show`
                      : '',
                  state: { _scrollToTop: scrollToTop },
              }),
              [basePath, record, resource, scrollToTop]
          )}
          label=" "
          onClick={stopPropagation}
          {...(rest)}
      >
          {icon}
      </Link>
  );
};

const defaultIcon = <ImageEye />;

const stopPropagation = e => e.stopPropagation();

CustomPureShowButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  scrollToTop: PropTypes.bool,
};

const CustomShowButton = memo(
  CustomPureShowButton,
  (props, nextProps) =>
      (props.record && nextProps.record
          ? props.record.id === nextProps.record.id
          : props.record == nextProps.record) && // eslint-disable-line eqeqeq
      props.basePath === nextProps.basePath &&
      props.to === nextProps.to &&
      props.disabled === nextProps.disabled
);

export default CustomShowButton;