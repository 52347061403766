import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useAuthState, Loading, useLogout } from 'react-admin';
const NotFound = (props) => {
    const { loading, authenticated } = useAuthState();
    const logout = useLogout();
    const handleClick = () => {
        if(authenticated) {
            window.location="/"
        } else {
            logout()
        }
    };
    return (
    <Card>
        <Title title="Not Found" />
        <CardContent>
        <section className="min-vh-100 flex-center">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                <h1 className="text-primary" >404</h1>
                <h4 className="mb-4">
                    Sorry! The Page Not Found <i className="la la-smile text-warning"></i></h4>
                <p>The Link You Folowed Probably Broken, or the page has been removed.</p><a
                    className="btn btn-rounded btn-primary mt-5"  onClick={handleClick}>Back</a>
                </div>
            </div>
            </div>
        </section>
        </CardContent>
    </Card>
    )
}

export default NotFound;