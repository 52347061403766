import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField, TextInput, useRecordContext } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import FeedbackIcon from '@material-ui/icons/Feedback';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];

const AmbassadorName = ({ source }) => {
    const record = useRecordContext();
    if(record) {
        return (<> { record.ambassador.firstName + ' ' + record.ambassador.lastName }  </>);
    } else {
        return (<> </> );
    }    
};

const Feedback = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><FeedbackIcon /> Feedback</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <List {...props} filters={postFilters} bulkActionButtons={false}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="feedbackType" label="Type" />
                            <TextField source="campaign.name" label="Campaign Name" />
                            <AmbassadorName source="ambassador.firstName" label="Ambassador Name" />
                            <TextField source="brand.brandName" label="Brand Name" />
                            <TextField source="comment" label="Comment" />
                            <DateField source="createdOn" label="Created On" showTime/>
                        </Datagrid>
                    </List>
                </Col>
            </Row>
        </Container>
    )
}

export default Feedback