
import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField,  TextInput ,ShowButton, useRecordContext, Loading} from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import { Container, Row , Col} from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ViewIcon from '@material-ui/icons/Visibility';

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const UserStatus = ({ source }) => {
    const record = useRecordContext();
    try {
        if(record ) {
            if(record && record.role === 'BRAND_MANAGER') {
                if(record.isDeleted || record.deletedOn) {
                    return <span className="text-danger">Deleted</span>
                } else if(record.isApproved) {
                    return <span className="text-success">Approved</span>
                } else if(record.isActive){
                    return <span className="text-info">Active</span>
                } else {
                    return <span className="text-warning">Away</span>
                }
            } else {
                if(record.isDeleted || record.deletedOn) {
                    return <span className="text-danger">Deleted</span>
                } else if(record.isApproved) {
                    return <span className="text-success">Approved</span>
                } else if(record.isActive && record.isEnabled && record.videoResumeUrl && record.interest){
                    return <span className="text-info">Active</span>
                } else {
                    return <span className="text-warning">Away</span>
                }
            }
            
        } else {
            return (<span className="text-warning">Away</span>);
        }    
    }
    catch(e) {
        console.error(e)
    }
};
const RecommenderStatus = ({ source }) => {
    const record = useRecordContext();
    try {
        if(record ) {
            if(record && record.role === 'BRAND_MANAGER') {
                return <span className="text-info">NA</span>
            } else {
                if(record.isActive && record.isEnabled && record.isApproved && record.interest){
                    return <span className="text-success">Active</span>
                } else {
                    return <span className="text-warning">Inactive</span>
                }
            }
            
        } else {
            return (<span className="text-warning">Inactive</span>);
        }    
    }
    catch(e) {
        console.error(e)
    }
};
const ResumeStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record[source] && record[source] !== null) {
        return (<><CheckIcon className="success" /> Ready </>);
    } else {
        return (<> <CloseIcon className="danger"/> Not Ready </>);
    }    
    }catch(e) {
        console.error(e)
    }
};
const MatchStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record.role === 'BRAND_MANAGER') {
        
        if(record.brands && record.brands !== null) {
            return (<><CheckIcon className="success" /> Set </>);
        } else {
            return (<> <CloseIcon className="danger"/> Not Set </>);
        } 
    } else {
        if(record[source] && record[source] !== null) {
            return (<><CheckIcon className="success" /> Set </>);
        } else {
            return (<> <CloseIcon className="danger"/> Not Set </>);
        }  
    }  
    }catch(e) {
        console.error(e)
    }
};

const ActiveStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record[source]) {
        return (<><CheckIcon className="success" /> Active </>);
    } else {
        return (<><CloseIcon className="danger" /> Away </> );
    }    
    }catch(e) {
        console.error(e)
    }
};

const EnableStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record[source]) {
        return (<><CheckIcon className="success" /> Enabled </>);
    } else {
        return (<><CloseIcon className="danger" /> Disabled </> );
    }    
    }catch(e) {
        console.error(e)
    }
};

const Users = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
        <h3><UserIcon /> Ambassadors</h3>
        <Row className="justify-content-md-center mt-5"> 

    <Col md="auto">
       <List {...props} title="User List" filters={postFilters} bulkActionButtons={false}>
           <Datagrid>
               <TextField source="id" label="ID"/>
               <TextField source="userName" label="Username"/>
               <TextField source="firstName" label="First Name"/>
               <TextField source="lastName" label="Last Name"/>
               <TextField source="email" label="Email"/>
               <TextField source="gender" label="Gender"/>
               <MatchStatus source="interest" label="Match Profile" sortable={false}/>
               <ActiveStatus source="isActive" label="Ambassador Active/Away" sortable={false}/>
               <ResumeStatus source="videoResumeUrl" label="Video Resume" sortable={false}/>
               <UserStatus source="status" label="User Status" sortable={false}/>
               <EnableStatus source="isEnabled" label="Enable/Disable" sortable={false}/>
               <RecommenderStatus source="isEnabled" label="Recommender Status" sortable={false}/>
               <DateField source="createdOn" label="Created On" showTime/>
               <ShowButton label="" className="MuiButton-root-custom btn iconbox iconbox-xs mr-3 " color="secondary" icon={<ViewIcon />} key="button" />
           </Datagrid>
       </List>
       </Col>
  </Row>
    </Container>
    )
}

export default Users