
import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField, TextInput, useRecordContext, ShowButton } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import FlagIcon from '@material-ui/icons/Flag';
import CustomDeleteAction from "../../utils/CustomDeleteAction"
import CustomShowAction from "../../utils/CustomShowAction"


import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ViewIcon from '@material-ui/icons/Visibility';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const CampaignStatus = ({ source }) => {
    const record = useRecordContext();
    try {
    if(record && record.deletedOn) {
        return (<><CloseIcon className="danger"/> Deleted </>);
    } else {
        return (<> <CheckIcon className="success" /> {record[source]}</>);
    }    
    }catch(e) {
        console.error(e)
    }
};
const Campaigns = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><FlagIcon /> Campaigns</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <List {...props} filters={postFilters} bulkActionButtons={false}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="name" label="Name" />
                            <TextField source="gigPrice" label="Gig Price" />
                            <TextField source="amount" label="Amount" />
                            <TextField source="brand.brandName" label="Brand" />
                            <CampaignStatus source="campaignStatus" label="Status"/>
                            <DateField source="createdOn" label="Created On" showTime/>
                            <CustomDeleteAction />
                            <CustomShowAction/>
                        </Datagrid>
                    </List>
                </Col>
            </Row>
        </Container>
    )
}

export default Campaigns