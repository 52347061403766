import React, { Component } from 'react';

class Header extends Component {

    render() {
        return (
<>
<header
  className="site-header sh-onScroll-shrink sh-onScroll-bg-white sh-onScroll-text-dark sh-onScroll-navbar-toggler-dark fixed-top">
  <nav className="site-nav container">
    <div className="navbar navbar-expand-lg">
      <a className="navbar-brand" href={() => false}>
        <img className="site-logo-default" src={process.env.PUBLIC_URL+"assets/img/logo/Brandbass_black.png"} alt="Site Logo" />
        <img className="site-logo-inverse" src={process.env.PUBLIC_URL+"assets/img/logo/Brandbass_black.png"}  alt="Site Logo" />
      </a>
    </div>
  </nav>
</header>
<section className="min-vh-20 flex-center py-8 shape-container header-bg"
>
<div className="container">
  <div className="row mt-2 align-items-center">
    <div className="col-md-6 text-white mt-2 ">
      <h2 className="rtx-bold display-md-1 text-white"></h2>
      
    </div>
  </div>
</div><img className="floating-shape bottom-0 left-0 right-0 w-100" src={process.env.PUBLIC_URL+"assets/img/seo/Group 2934.svg"} alt="" />
</section>
</>
        );
    }
};

export default Header;