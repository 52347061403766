import { AuthProvider } from 'react-admin';
import { authenticate } from '../RestAPI';
import { getIssues, getDashboardCounts, getInvites, getCampaigns, getFeedbacks, getGigs, getAmbassadors, getBrands} from '../RestAPI';
import { getSetting, updateSetting, getDashboardUser, deleteCampaign, deleteGig, getCampaign, deleteInvite, approveInvite, getEarnings, updatePaymentStatus, createInvite, getPayments } from '../RestAPI';

export const dataProvider = {
    getList: (resource, params) => {
        return new Promise((resolve, reject) => {
            try {
                console.log("getList " ,resource)
                console.log("getList " ,params)
                console.log("getList " ,resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'issues':
                        resolve(getIssues(params));
                        break;
                    case 'campaigns':
                        resolve(getCampaigns(params));
                        break;
                    case 'earnings':
                        resolve(getEarnings(params));
                        break;
                    case 'payments':
                        resolve(getPayments(params));
                        break;
                    case 'invites':
                        resolve(getInvites(params));
                        break;
                    case 'gigs':
                        resolve(getGigs(params));
                        break;
                    case 'feedback':
                        resolve(getFeedbacks(params));
                        break;
                    case 'dashboard':
                        resolve(getDashboardCounts());
                        break;
                    case 'ambassadors':
                        resolve(getAmbassadors(params))
                        break;
                    case 'brands':
                        resolve(getBrands(params))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });

    },
    getOne: (resource, params) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("getOne " ,resource)
                console.log("getOne " ,params)
                console.log("getOne ", resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'settings':
                        resolve(getSetting())
                        break;
                    case 'ambassadors':
                        resolve(getDashboardUser(params.id))
                        break;
                    case 'brands':
                        resolve(getDashboardUser(params.id))
                        break;
                    case 'campaigns':
                        resolve(getCampaign(params.id))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });
    },
    getMany: (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    create: (resource, params) => {
        return new Promise((resolve, reject) => {
            try {
                console.log("create " ,resource)
                console.log("create " ,params)
                console.log("create ", resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'invites':
                        resolve(createInvite(params))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });
    },
    update: (resource, params) => {
        return new Promise((resolve, reject) => {
            try {
                console.log("update " ,resource)
                console.log("update " ,params)
                console.log("update ", resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'settings':
                        resolve(updateSetting(params))
                        break;
                    case 'invites':
                        resolve(approveInvite(params.id))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });
    },
    updateMany: (resource, params) => {
        return new Promise((resolve, reject) => {
            try {
                console.log("updateMany " ,resource)
                console.log("updateMany " ,params)
                console.log("updateMany ", resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'earnings':
                        resolve(updatePaymentStatus(params))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });
    },
    delete: (resource, params) => {
        return new Promise((resolve, reject) => {
            try {
                console.log("delete " ,resource)
                console.log("delete " ,params)
                console.log("delete ", resource.toLowerCase())
                switch (resource.toLowerCase()) {
                    case 'campaigns':
                        resolve(deleteCampaign(params.id))
                        break;
                    case 'invites':
                        resolve(deleteInvite(params.id))
                        break;
                    case 'gigs':
                        resolve(deleteGig(params.id))
                        break;
                    default:
                        reject('invalid endpoint')
                }
            } catch (error) {
                console.error(error)
                reject(error)
            };

        });
    },
    deleteMany: (resource, params) => Promise,
}
