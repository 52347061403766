import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import Header from "./Header"
import Footer from "./Footer"
import { ToastContainer, toast } from 'react-toastify';
class MyLoginPage extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.form = React.createRef();
        this.state = {
          username: "",
          password: ""
        };
        this.submit = this.submit.bind(this);
        this.handle = this.handle.bind(this);
      }
    submit = (e) => {
        e.preventDefault();
        // gather your data/credentials here
        const credentials = { 
            username:this.state.username,
            password:this.state.password
        };
        console.log(credentials)
        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
    }
    handle = (e) =>{
        console.log(e);
        console.log(e.target.name);
        console.log(e.target.value);
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]:value});
    }

    render() {
        return (
            <>
             <Header />
            <section className="min-vh-70">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center px-5 px-md-10">
                  <div className="my-2">
                    <h2 className="mb-6">
                      Welcome back, please login
                    </h2>
                    <form method="POST" onSubmit={this.submit}>
                      <input className="form-control mt-4" value={this.state.username} name="username" onChange={this.handle} type="text" placeholder="Username" />
                      <input className="form-control mt-4" value={this.state.password} name="password" onChange={this.handle} type="password" placeholder="Password" />
                      <button className="btn btn-primary btn-block mt-4" type="submit">Login</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer autoClose={8000} position="bottom-center" pauseOnHover theme="dark"/>
          <Footer />
          </>
        );
    }
};

export default connect(undefined, { userLogin })(MyLoginPage);