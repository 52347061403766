
import './App.css';
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import {  Route } from "react-router-dom";
import {authProvider} from './providers/authProvider';
import {dataProvider} from './providers/dataProvider';
import IssueList from './modules/Issue/IssueList';
import Dashboard from './modules/Dashboard/Dashboard';
import Invites from './modules/Invites/Invites';
import CreateInvite from './modules/Invites/CreateInvite';
import Ambassadors from './modules/Users/Ambassadors';
import User from './modules/Users/User';
import Campaign from './modules/Campaign/Campaign';
import Campaigns from './modules/Campaign/Campaigns';
import Gigs from './modules/Gigs/Gigs';
import Feedback from './modules/Feedback/Feedback';
import Settings from './modules/Settings';

import Brands from './modules/Users/Brands';
import NotFound from './NotFound';
import MyLoginPage from './MyLoginPage';
import MyLayout from './modules/MyLayout';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FeedbackIcon from '@material-ui/icons/Feedback';
import UserIcon from '@material-ui/icons/People';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import BugReportIcon from '@material-ui/icons/BugReport';
import FlagIcon from '@material-ui/icons/Flag';
import VideocamIcon from '@material-ui/icons/VideoLibrary';

function App() {
  return (
    <React.Fragment>
     
    <Admin 
    loginPage={MyLoginPage}
    layout={MyLayout}
      catchAll={NotFound}
      dataProvider={dataProvider} // provider to handle data
      authProvider={authProvider} // provider to handle auth
       dashboard={Dashboard} // The default landing component displayed when BrandBass Admin log in
      
      customRoutes={[
        <Route
            exact
            key="settings"
            path="/settings"
            component={Settings.edit}
        />
    ]}
    >
      { permissions => { 
            if (!permissions) {
                return [[]];
            }
            return [
          <Resource name="ambassadors" list={Ambassadors} show={User} icon={UserIcon}/>,
          <Resource name="brands" list={Brands} show={User} icon={BusinessCenter}/>,
          <Resource name="invites" list={Invites} create={CreateInvite} icon={NotificationsActiveIcon}/>,
          <Resource name="campaigns" list={Campaigns} show={Campaign} icon={FlagIcon}/>,
          <Resource name="gigs" list={Gigs} icon={VideocamIcon}/>,
          <Resource name="issues" list={IssueList} icon={BugReportIcon}/>,
          <Resource name="feedback" list={Feedback} icon={FeedbackIcon}/>,
          <Resource name="settings" />,
          <Resource name="earnings" />,
          <Resource name="payments" />
        ];
        }
      }
    </Admin>
    
    </React.Fragment>
  );
}

export default App;
