
import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField,  TextInput ,ShowButton, useRecordContext} from 'react-admin';
import ViewIcon from '@material-ui/icons/Visibility';
import { Container, Row , Col} from 'react-bootstrap';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const UserStatus = ({ source }) => {
    const record = useRecordContext();
    try {
        if(record ) {
            if(record && record.role === 'BRAND_MANAGER') {
                if(record.isDeleted || record.deletedOn) {
                    return <span className="text-danger">Deleted</span>
                } else if(record.isApproved) {
                    return <span className="text-success">Approved</span>
                } else if(record.isActive){
                    return <span className="text-info">Active</span>
                } else {
                    return <span className="text-warning">Away</span>
                }
            } else {
                if(record.isDeleted || record.deletedOn) {
                    return <span className="text-danger">Deleted</span>
                } else if(record.isApproved) {
                    return <span className="text-success">Approved</span>
                } else if(record.isActive && record.isEnabled && record.videoResumeUrl && record.interest){
                    return <span className="text-info">Active</span>
                } else {
                    return <span className="text-warning">Away</span>
                }
            }
            
        } else {
            return (<span className="text-warning">Away</span>);
        }    
    }
    catch(e) {
        console.error(e)
    }
};
const Users = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
        <h3><BusinessCenter /> Brands</h3>
        <Row className="justify-content-md-center mt-5"> 

    <Col md="auto">
       <List {...props} title="User List" filters={postFilters} bulkActionButtons={false}>
           <Datagrid>
               <TextField source="id" label="ID"/>
               <TextField source="userName" label="Username"/>
               <TextField source="firstName" label="First Name"/>
               <TextField source="lastName" label="Last Name"/>
               <TextField source="email" label="Email"/>
               <TextField source="gender" label="Gender"/>
               <UserStatus source="status" label="User Status" sortable={false}/>
               <DateField source="createdOn" label="Created On" showTime/>
               <ShowButton label="" className="MuiButton-root-custom btn iconbox iconbox-xs mr-3 " color="secondary" icon={<ViewIcon />} key="button" />
           </Datagrid>
       </List>
       </Col>
  </Row>
    </Container>
    )
}

export default Users