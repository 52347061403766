
import { useAuthenticated } from 'react-admin';
import { List, Datagrid, TextField, DateField, TextInput } from 'react-admin';
import { Container, Row, Col } from 'react-bootstrap';
import BugReportIcon from '@material-ui/icons/BugReport';
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />
];
const IssueList = (props) => {
    useAuthenticated()
    return (
        <Container fluid >
            <h3><BugReportIcon /> Issues</h3>
            <Row className="justify-content-md-center mt-5">

                <Col md="auto">
                    <List {...props} filters={postFilters} bulkActionButtons={false}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="issueType" label="Type/Entity" sortable={false}/>
                            <TextField source="moduleId" label="EntityID" />
                            <TextField source="campaign.name" label="Campaign" />
                            <TextField source="issueDescription" label="Description" sortable={false} />
                            <TextField source="reason" label="Reason" />
                            <TextField source="createdBy.userName" label="User" />
                            <TextField source="issueStatus" label="Status" sortable={false}/>
                            <DateField source="createdOn" label="Created On" showTime/>
                        </Datagrid>
                    </List>
                </Col>
            </Row>
        </Container>
    )
}

export default IssueList