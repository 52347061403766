import * as React from 'react';
//import AppBar from '@material-ui/core/AppBar';
import { AppBar, UserMenu } from "react-admin";

import { withStyles } from '@material-ui/core/styles'
import MyUserMenu from './MyUserMenu';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
}


const MyAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar {...props} userMenu={<MyUserMenu />} color="transparent" className="site-header shape-container header-bg sticky-top fixed-top navbar-expand" style={{ visibility:'visible'}}>
        <a className="navbar-brand" href={() => false}>
        <img className="site-logo-default" src={process.env.PUBLIC_URL+"assets/img/logo/Brandbass_black.png"} alt="Site Logo" />
        <img className="site-logo-inverse" src={process.env.PUBLIC_URL+"assets/img/logo/Brandbass_black.png"}  alt="Site Logo" />
      </a>
      
      <span className={classes.spacer} />
        <img className="floating-shape bottom-0 left-0 right-0 w-100" src={process.env.PUBLIC_URL+"assets/img/seo/Group 2934.svg"} alt="" />
        {window.location.hostname !== "dashboard.brandbass.ai" && (
            <>
            <span className={classes.spacer} />
            <p className="ribbon">
            <span className="text"><strong className="bold">DEMO SITE!!</strong></span>
            </p></>
        )}
    </AppBar>
))

export default MyAppBar